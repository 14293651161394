import { Box, Heading, HStack, Icon, SimpleGrid, Spacer, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { FcMoneyTransfer, FcSettings, FcCurrencyExchange, FcBusinessContact } from 'react-icons/fc'
import { FiChevronRight } from 'react-icons/fi'

import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../Config/firebase'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'

function AccountMicro() {
	const navigate = useNavigate()
	const value = useContext(GlobalContext)
	const fontCol = useColorModeValue('blackAlpha.700', 'whiteAlpha.700')
	const Menu = [
		{ icon: FcSettings, title: 'Edit Profile', link: 'edit-profile' },
		// { icon: FcCurrencyExchange, title: 'Manage Subscriptions', link: 'subscriptions' },
		// { icon: FcMoneyTransfer, title: 'Add / Change Credit Card', link: 'credit-card' },
		{ icon: FcBusinessContact, title: 'My Order History', link: 'order-history' }
	];

	return (
		<Box h='100%'>
			<SimpleGrid columns='3' bgColor='brand.1' p='2'>
				<Heading size="md" color={'black'}>Settings</Heading>
			</SimpleGrid>
			{Menu.map((x, i) =>
				<Box key={i} onClick={() => { value?.auth ? navigate(`/account/${x.link}`) : navigate('/authentication/login') }} cursor='pointer' shadow={'md'} rounded={5} _hover={{
					transform: 'scale(1.02)',
					transition: 'transform 0.2s ease-in-out',
				}}>
					<HStack m='2' h='75px'>
						<Icon as={x.icon} fontSize={'x-large'} />
						<Text color={fontCol} fontWeight={"semibold"}>{x.title}</Text>
						<Spacer />
						<FiChevronRight />
					</HStack>
				</Box>
			)
			}

		</Box>
	)
}

export default AccountMicro