import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getSingleDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi'
import { useParams } from 'react-router-dom'
import { Box, Button, Center, Container, FormControl, Input, Select, SimpleGrid, Text, Textarea, useToast } from '@chakra-ui/react'
import axios from 'axios'
import { createIpaymuPayment } from '../../Api/finpayPaymentApi'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'
import { getRandomString } from '../../Utils/RandomString'
import JneForm from '../../Components/Forms/JneForm'

const FormTicketMicroSite = () => {
	const params = useParams();
	const [formFields, setFormFields] = useState();
	const [formValues, setFormValues] = useState({});
	const [formDetails, setFormDetails] = useState({});
	const [ticketDetails, setTicketDetails] = useState({});
	const value = useContext(GlobalContext);
	const toast = useToast();
	const [ipaymuResponse, setIpaymuResponse] = useState({});
	const [loading, setLoading] = useState(false);
	const [selectedDestination, setSelectedDestination] = useState({});
	const [selectedCourier, setSelectedCourier] = useState({});
	const [fullAddress, setFullAddress] = useState("");
	const [orderCredential, setOrderCredential] = useState({});
	const [updateData, setUpdateData] = useState({});
	const [isShowingIframe, setIsShowingIframe] = useState(false);
	const iframe = document.getElementById('paymentPage'); // Replace 'myIframe' with your iframe's ID

	const getData = async () => {
		getSingleDocumentFirebase('tickets', params.id).then((ticket) => {
			setTicketDetails(ticket);
			getSingleDocumentFirebase('forms', ticket?.formId).then((form) => {
				setFormDetails(form);
				setFormFields(form.form_fields)
			})
		})
	};


	// const requestOrderJne = async (updateData) => {
	// 	// console.log(args)
	// 	const url = "https://requestorder-qwuyxef5gq-uc.a.run.app";
	// 	const config = {
	// 		method: 'post',
	// 		maxBodyLength: Infinity,
	// 		withCredentials: false,
	// 		url: url,
	// 		data: {
	// 			is_production: false,
	// 			username: "TESTAPI",
	// 			api_key: "25c898a9faea1a100859ecd9ef674548",
	// 			OLSHOP_BRANCH: "CGK000",
	// 			OLSHOP_CUST: "TESTAKUN",
	// 			OLSHOP_ORDERID: getRandomString(),
	// 			OLSHOP_SHIPPER_NAME: "DBRAND",
	// 			OLSHOP_SHIPPER_ADDR1: "Jl. RS. Fatmawati Raya No.30 H, RT.1/RW.10, Cilandak Bar., Kec. Cilandak, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12430",
	// 			OLSHOP_SHIPPER_ADDR2: "CILANDAK",
	// 			OLSHOP_SHIPPER_ADDR3: "CILANDAK",
	// 			OLSHOP_SHIPPER_CITY: "JAKARTA",
	// 			OLSHOP_SHIPPER_REGION: "JAKARTA",
	// 			OLSHOP_SHIPPER_ZIP: "12430",
	// 			OLSHOP_SHIPPER_PHONE: "+6289876543212",
	// 			OLSHOP_ORIG: "CGK10000",




	// 			OLSHOP_RECEIVER_NAME: updateData.name,
	// 			OLSHOP_RECEIVER_ADDR1: fullAddress,
	// 			OLSHOP_RECEIVER_ADDR2: "",
	// 			OLSHOP_RECEIVER_ADDR3: "",
	// 			OLSHOP_RECEIVER_CITY: selectedDestination.City_Name,
	// 			OLSHOP_RECEIVER_REGION: selectedDestination.City_Name,
	// 			OLSHOP_RECEIVER_ZIP: "12365",
	// 			OLSHOP_RECEIVER_PHONE: "+62" + updateData.phoneNumber.slice(1),
	// 			OLSHOP_DEST: selectedDestination.City_Code,




	// 			OLSHOP_QTY: updateData.quantity || "1",
	// 			OLSHOP_WEIGHT: "1",
	// 			OLSHOP_GOODSDESC: "Buku Bisa Bikin Brand 1 - Subiakto Priosoedarsono",
	// 			OLSHOP_GOODSVALUE: "555000",
	// 			OLSHOP_GOODSTYPE: "1",
	// 			OLSHOP_INST: "TEST",
	// 			OLSHOP_INS_FLAG: "N",

	// 			// OLSHOP_SERVICE: args?.service_display, //selectedService.service_display
	// 			OLSHOP_SERVICE: selectedCourier?.service_display, //selectedService.service_display
	// 			OLSHOP_COD_FLAG: "N",
	// 			OLSHOP_COD_AMOUNT: "0"
	// 		},
	// 	};

	// 	try {
	// 		console.log("requesting order...")
	// 		const result = await axios(config);
	// 		console.log(result.data.data.detail[0])
	// 		setOrderCredential(result.data.data.detail[0]);
	// 		return result.data.data.detail[0];
	// 	} catch (error) {
	// 		if (error?.response?.data?.detail[0]?.status === 'sukses') {
	// 			console.log(error?.response?.data?.detail[0]);
	// 			setOrderCredential(error?.response?.data?.detail[0]);
	// 			return error?.response?.data?.detail[0]
	// 		} else {
	// 			console.log(error?.response?.data?.detail[0]?.reason, "error requesting order");
	// 		};
	// 	} finally {

	// 	};
	// };



	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
	};

	// const renderFormFields = () => {
	//   if (formFields?.length > 0) {
	//     return formFields?.map((field) => {
	//       const { label, type, name, placeholder, isRequired, options } = field;
	//       const inputPlaceholder = placeholder || '';
	//       const inputIsRequired = isRequired || false;
	//       const inputProps = { name, onChange: handleInputChange, value: formValues[name] || '' };

	//       const handleSubmit = () => {
	//         console.log('Form values:', formValues);
	//         console.log('Form Id:', params.id)
	//         // Implement your form submission logic here
	//       };

	//       return (
	//         <FormControl key={name} isRequired={inputIsRequired}>
	//           {type !== "button" && (
	//             <Text>{label}{inputIsRequired ? <span style={{ color: 'red' }}>*</span> : null}</Text>

	//           )}
	//           {type === 'text' && <Input type="text" placeholder={inputPlaceholder} {...inputProps} />}
	//           {type === 'number' && <Input type="number" placeholder={inputPlaceholder} {...inputProps} />}
	//           {type === 'email' && <Input type="email" placeholder={inputPlaceholder} {...inputProps} />}
	//           {type === 'textarea' && <Textarea name={name} placeholder={inputPlaceholder} {...inputProps} />}
	//           {type === 'select' && (
	//             <Select name={name} placeholder={inputPlaceholder} {...inputProps}>
	//               {options?.map((option, index) => (
	//                 <option key={index} value={option}>
	//                   {option}
	//                 </option>
	//               ))}
	//             </Select>
	//           )}
	//           {type === 'date' && <Input type="date" placeholder={inputPlaceholder} {...inputProps} />}
	//           {type === 'time' && <Input type="time" placeholder={inputPlaceholder} {...inputProps} />}
	//           {type === 'file' && <Input type="file" placeholder={inputPlaceholder} {...inputProps} />}
	//           <Stack>
	//             {type === 'button' && <Button onClick={handleSubmit} colorScheme="teal">{label}</Button>}
	//           </Stack>
	//         </FormControl>
	//       );
	//     });
	//   }

	// };

	const renderFormFields = (opportunityValue) => {
		if (formFields?.length > 0) {
			return formFields?.map((field, index) => {
				const { label, type, name, placeholder, isRequired, options, formId } = field;
				const inputPlaceholder = placeholder || '';
				const inputIsRequired = isRequired || false;
				const inputProps = { name, onChange: handleInputChange, value: formValues[name] || '' };

				const handleSubmit = async () => {
					let updateData = formValues
					setUpdateData(updateData)
					if (params.id === "5fVAM1fDapHj2EaUpmdt") {
						if (!updateData?.name) {
							return toast({
								title: "Please fill out name",
								description: "",
								isClosable: true,
								duration: 9000,
								status: "error"
							})
						} else if (!updateData?.email) {
							return toast({
								title: "Please fill out email",
								description: "",
								isClosable: true,
								duration: 9000,
								status: "error"
							})
						} else if (!updateData?.phoneNumber) {
							return toast({
								title: "Please fill out phone number",
								description: "",
								isClosable: true,
								duration: 9000,
								status: "error"
							})
						} else if (!selectedDestination) {
							return toast({
								title: "Please select City/Region",
								description: "",
								isClosable: true,
								duration: 9000,
								status: "error"
							})
						} else if (!fullAddress) {
							return toast({
								title: "Please fill out Full Address",
								description: "",
								isClosable: true,
								duration: 9000,
								status: "error"
							})
						} else if (Object.keys(selectedCourier)?.length === 0) {
							return toast({
								title: "Please select Courier",
								description: "",
								isClosable: true,
								duration: 9000,
								status: "error"
							})
						} else {
							// const resultJne = await requestOrderJne(updateData)
							// console.log(resultJne, "resultJne")
							// if (Object.keys(resultJne)?.length === 0) {
							// 	console.log(resultJne, "resultJne")
							// 	return toast({
							// 		title: "Error requesting shipping order",
							// 		description: "",
							// 		isClosable: true,
							// 		duration: 9000,
							// 		status: "error"
							// 	})
							// }
							updateData.selectedDestination = selectedDestination;
							updateData.fullAddress = fullAddress;
							updateData.selectedCourier = selectedCourier;
							updateData.orderCredential = orderCredential;
							updateData.withShipping = true;
							updateData.courier = "JNE";
						};
					}


					updateData.formId = formId
					updateData.opportunity_value = opportunityValue ? opportunityValue : "0"
					// updateData.email = "info@deoapp.com"
					// updateData.phoneNumber = moment(new Date()).valueOf().toString()
					// updateData.name = "visitor"

					const formFields = updateData

					try {
						setLoading(true);
						// console.log(formFields, "formFields")
						const resultSubmitLeads = await axios.post('https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/createLead', formFields);
						console.log(resultSubmitLeads?.data?.message, "resultSubmitLeads")


						if (window.location.href?.includes("bisabikinbrand1") ||
							params.id === "5fVAM1fDapHj2EaUpmdt") {
							const ipaymuPayload = {
								companyId: "Z55P0SgE1tztgKziGsmE",
								projectId: "PQwxxgMvG8ivkxyP5sHs",
								product: [
									ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
										"Bisa Bikin Brand 1" :
										ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ?
											"Indonesia Spicing The World Event" :
											ticketDetails?.title
								],
								qty: [
									"1"
								],
								price: [
									ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
										"555000" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ? "1387500" : String(120000 * 1.1 + parseInt(selectedCourier?.price))
								],
								amount: ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
									"555000" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ? "1387500" : String(120000 * 1.1 + parseInt(selectedCourier?.price)),
								returnUrl: "https://bisabikinbrand1.deoapp.site", //your thank you page url
								cancelUrl: "https://your-website.com/cancel-page", // your cancel page url
								notifyUrl: "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/ipaymuCallbackPayment",
								referenceId: getRandomString(), // your reference id or transaction id
								buyerName: updateData?.name, // optional
								buyerPhone: updateData?.phoneNumber, // optional
								buyerEmail: updateData?.email,// optional
								va: ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
									"1179005223944575" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ? "1179002120001001" : "1179005223944575",
								apiKey: ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
									"C8132D77-7243-4556-B4FA-6B61669FD406" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ? "8A34D5B7-F3AE-42DC-9FFC-7BC0BBD121E2" : "C8132D77-7243-4556-B4FA-6B61669FD406",
								target: "production"
								// va: "0000007806848932", //dev
								// apiKey: "SANDBOX60851B52-A194-4C13-9531-031E5BD3E5F2",//dev
								// target: "development"//dev
							};

							ipaymuPayload.lead_id = resultSubmitLeads?.data?.message?.leadId;

							try {
								// console.log(ipaymuPayload, "ipaymuPayload")
								const resultIpaymu = await createIpaymuPayment(ipaymuPayload);
								console.log(resultIpaymu, "resultIpaymu");



								if (resultIpaymu?.data?.status) {
									setIpaymuResponse(resultIpaymu?.data);
									try {
										let dataEmail = {}
										if (ticketDetails?.id === "xlGuDzpmezYiTyaa7op5") dataEmail = {
											platform_name: ticketDetails?.title?.length > 20 ? ticketDetails?.title?.slice(0, 16) + "..." : ticketDetails?.title,
											sender_email: ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
												"bisabikinbrand1@gmail.com" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ?
													"indonesiaspicingtheworld5@gmail.com" : "",
											recipient_email: updateData?.email,
											recipient_name: updateData?.name,
											cc: ['reinhart@importir.co'],
											subject: updateData.subject || "Pendaftaran " + ticketDetails?.title,
											title: "INVOICE",
											message: `<p>Hallo ${updateData?.name}</p><p> / ${updateData?.email}</p>
											<p> Anda telah melakukan pendaftaran ${ticketDetails?.title},</p><p> 
											berikut informasi Anda: ${ticketDetails?.title}</p><p><br></p><p> 
											Data Anda Nama: ${updateData?.name}</p><p> Phone: ${updateData?.phoneNumber}</p>
											<p> Email: ${updateData?.email}</p>


											<p>Harga : Rp.${updateData?.opportunity_value}</p>
											<p>PPN (11%): Rp.${(parseInt(updateData?.opportunity_value) * 0.11)?.toFixed()}</p>

											<p><strong> Total Bayar: Rp.${(parseInt(updateData?.opportunity_value) * 1.11)?.toFixed()}</strong></p>

											<p> Silahkan melakukan pembayaran ke link berikut klik disini Pastikan data yang kamu diisi sudah BENAR</p>
											<p><br></p><p><br></p><p><br></p><p>Silahkan melakukan pembayaran pada link berikut di bawah ini:</p>
											<p><br></p><p>${resultIpaymu?.data?.data?.Data?.Url}</p>
											<br>
											<p>*Link pembayaran akan expire dalam 24 jam, jika tidak dilakukan pembayaran maka anda harus
											mendaftar kembali.</p>
											<p>CP : Muhammad Kasim https://wa.me/6281282518873</p>

											`
										}
										if (ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz") dataEmail = {
											platform_name: ticketDetails?.title?.length > 20 ? ticketDetails?.title?.slice(0, 16) + "..." : ticketDetails?.title,
											sender_email: "bisabikinbrand1@gmail.com",
											recipient_email: updateData?.email,
											recipient_name: updateData?.name,
											cc: ['reinhart@importir.co'],
											subject: updateData.subject || "Pembelian " + ticketDetails?.title,
											title: "INVOICE",
											message: `<p>Hallo ${updateData?.name}</p><p> / ${updateData?.email}</p>
											<p> Anda telah melakukan pemesanan ${ticketDetails?.title},</p><p> 
											berikut informasi Anda: ${ticketDetails?.title}</p><p><br></p><p> 
											Data Anda Nama: ${updateData?.name}</p><p> Phone: ${updateData?.phoneNumber}</p>
											<p> Email: ${updateData?.email}</p>


											<p>Harga : Rp.${updateData?.opportunity_value}</p>
											<p>PPN (11%): Rp.${(parseInt(updateData?.opportunity_value) * 0.11)?.toFixed()}</p>


											<p><strong> Total Bayar: Rp.${(parseInt(updateData?.opportunity_value) * 1.11)?.toFixed()}</strong></p>



											<p> Silahkan melakukan pembayaran ke link berikut klik disini Pastikan data yang kamu diisi sudah BENAR</p>
											<p><br></p><p><br></p><p><br></p><p>Silahkan melakukan pembayaran pada link berikut di bawah ini:</p>
											<p><br></p><p>${resultIpaymu?.data?.data?.Data?.Url}</p>
											<br>
											<p>*Link pembayaran akan expire dalam 24 jam, jika tidak dilakukan pembayaran maka anda harus
											mendaftar kembali.</p>
											<p>PEMBELIAN BUKU DENGAN PENGIRIMAN AKAN DIKENAKAN BIAYA PENGIRIMAN DI LUAR BIAYA BUKU. Harap menunggu email konfirmasi lebih lanjut untuk form alamat pengiriman.</p>
											<p>CP : Muhammad Kasim https://wa.me/6281282518873</p>
											`
										};

										if (ticketDetails?.id === "5fVAM1fDapHj2EaUpmdt") {
											dataEmail = {
												platform_name: ticketDetails?.title?.length > 20 ? ticketDetails?.title?.slice(0, 16) + "..." : ticketDetails?.title,
												sender_email: "bisabikinbrand1@gmail.com",
												recipient_email: updateData?.email,
												recipient_name: updateData?.name,
												cc: ['reinhart@importir.co'],
												subject: updateData.subject || "Pembelian " + ticketDetails?.title,
												title: "INVOICE",
												message: `<p>Hallo ${updateData?.name}</p><p> / ${updateData?.email}</p>
												<p> Anda telah melakukan pemesanan ${ticketDetails?.title},</p><p> 
												berikut informasi Anda: ${ticketDetails?.title}</p><p><br></p><p> 
												Data Anda Nama: ${updateData?.name}</p><p> Phone: ${updateData?.phoneNumber}</p>
												<p> Email: ${updateData?.email}</p>


												<p>Harga : Rp.${updateData?.opportunity_value}</p>
												<p>PPN (11%): Rp.${(parseInt(updateData?.opportunity_value) * 0.11)?.toFixed()}</p>


												<p><strong> Total Bayar: Rp.${(parseInt(updateData?.opportunity_value) * 1.11)?.toFixed()}</strong></p>



												<p> Silahkan melakukan pembayaran ke link berikut klik disini Pastikan data yang kamu diisi sudah BENAR</p>
												<p><br></p><p><br></p><p><br></p><p>Silahkan melakukan pembayaran pada link berikut di bawah ini:</p>
												<p><br></p><p>${resultIpaymu?.data?.data?.Data?.Url}</p>
												<br>
												<p>*Link pembayaran akan expire dalam 24 jam, jika tidak dilakukan pembayaran maka anda harus
												mendaftar kembali.</p>
												<p>PEMBELIAN BUKU DENGAN PENGIRIMAN AKAN DIKENAKAN BIAYA PENGIRIMAN DI LUAR BIAYA BUKU. Harap menunggu email konfirmasi lebih lanjut untuk form alamat pengiriman.</p>
												<p>CP : Muhammad Kasim https://wa.me/6281282518873</p>
												`
											};
											try {
												await setDocumentFirebase('orders', ipaymuPayload?.referenceId, { ...formFields, product: ticketDetails?.title, is_production: false }, "PQwxxgMvG8ivkxyP5sHs");
												console.log("creating order document with ID: ", ipaymuPayload?.referenceId)
											} catch (error) {
												console.log("error creating order document ::::::", error)
											}
										}


										const res = await axios.post("https://new-third-party.importir.com/api/email/send-message", dataEmail)
										// console.log(dataEmail, "dataEmail")
										// console.log({ res })

									} catch (error) {
										console.log(error.message, "error sending email")
									}



									toast({
										title: 'Success',
										description: 'Registration success',
										status: 'success',
										isClosable: true,
										duration: 9000
									});
									window.open(resultIpaymu?.data?.data?.Data?.Url);
								} else {
									console.log("resultIpaymu", resultIpaymu);
									toast({
										title: 'Error',
										// description: resultIpaymu?.data?.message,
										description: 'something wrong happened',
										status: 'error',
										isClosable: true,
										duration: 9000
									})
								}
							} catch (error) {
								// console.log(error);
								toast({
									title: 'error',
									description: error.message,
									isClosable: true,
									duration: 9000,
									status: 'error'
								})
							} finally {
								setLoading(false)
							}
						}


						console.log("formDetails", formDetails.paymentMethod === "xendit")

						if (formDetails.paymentMethod === "xendit") {
							setIsShowingIframe(true);
							window.open(`https://crm.deoapp.com/payment/ticket/xendit/${value.webConfig.projectsId}/${updateData.phoneNumber}/${updateData.name}/${encodeURIComponent(updateData.formId)}`)
						}


					} catch (error) {
						console.log(error, 'ini error')
					} finally {
						setLoading(false);
						iframe?.contentWindow?.focus();
					}
					// Implement your form submission logic here
				};

				return (
					<FormControl key={index} isRequired={inputIsRequired}>
						{type !== "button" && (
							<Text>{label}{inputIsRequired ? <span style={{ color: 'red' }}>*</span> : null}</Text>
						)}
						{type === 'text' && <Input my={3} type="text" placeholder={inputPlaceholder} {...inputProps} />}
						{type === 'number' && <Input my={3} type="number" placeholder={inputPlaceholder} {...inputProps} />}
						{type === 'email' && <Input my={3} type="email" placeholder={inputPlaceholder} {...inputProps} />}
						{type === 'textarea' && <Textarea name={name} placeholder={inputPlaceholder} {...inputProps} />}
						{type === 'select' && (
							<Select name={name} placeholder={inputPlaceholder} {...inputProps}>
								{options?.map((option, index) => (
									<option key={index} value={option}>
										{option}
									</option>
								))}
							</Select>
						)}
						{type === 'date' && <Input my={3} type="date" placeholder={inputPlaceholder} {...inputProps} />}
						{type === 'time' && <Input my={3} type="time" placeholder={inputPlaceholder} {...inputProps} />}
						{type === 'file' && <Input my={3} type="file" placeholder={inputPlaceholder} {...inputProps} />}

						{type === "request" &&
							<JneForm
								my={3}
								selectedDestination={selectedDestination}
								setSelectedDestination={setSelectedDestination}
								selectedCourier={selectedCourier}
								setSelectedCourier={setSelectedCourier}
								setFullAddress={setFullAddress}
								orderCredential={orderCredential}
								setOrderCredential={setOrderCredential}
							// requestOrderJne={requestOrderJne}
							/>}

						{type === 'button' && <Button my={5} w="100%"
							isLoading={loading} isDisabled={loading} loadingText="Submitting data..."
							onClick={handleSubmit} colorScheme="blue">{label}</Button>}


					</FormControl>
				);
			});
		}
	};

	useEffect(() => {
		getData()
	}, [])

	if (ipaymuResponse?.data?.Data?.Url) return (
		<Box h='80vh' w='full' p='5' display='flex' flexDir='column' justifyContent='center' alignContent='center'>
			<Text fontWeight='bold' textAlign='center' mb='5'>
				You should be redirected to Payment Page, if not please click this link below:
			</Text>
			<a href={ipaymuResponse?.data?.Data?.Url} rel='noopener noreferrer' target='_blank' style={{ color: 'blue', textAlign: 'center' }}
				onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
				onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
			>{ipaymuResponse?.data?.Data?.Url}</a>
		</Box>
	);

	return (
		<>
			<Box p={2}>
				<Container>
					{formDetails?.description}
				</Container>
				{renderFormFields((parseInt(ticketDetails?.price) * 1.11)?.toFixed())}


				{params?.id === "FHFdfaoIpGKOYaOSnBwz" ?
					<Text color="red" fontWeight='bold' fontSize={12}>*Khusus pembelian buku dengan pengiriman akan dikenakan biaya tambahan di luar biaya pre-order buku</Text>
					: <></>}


				{/* THIS IS ONLY FOR INDONESIA SPICING THE WORLD  */}
				{params.id === "xlGuDzpmezYiTyaa7op5" ? <>
					<Center>
						<SimpleGrid gap={2} columns='2' w='full'>
							<Text>Harga : </Text>
							<Text>Rp 1,250,000</Text>
							<Text>PPN (11%) : </Text>
							<Text>Rp 137,000</Text>
							<Text fontWeight='bold'>Total : </Text>
							<Text fontWeight='bold'>Rp 1,391,000</Text>
						</SimpleGrid>
					</Center>
				</> : <></>}


				{/* THIS IS ONLY FOR BISA BIKIN BRAND 1   */}

				{/* {formValues?.pilihan === 'Shipping - Shipper (JNE, JNT, TIKI, POS)' ?
					<>
						<Stack p={2} gap={2}>
							<Input placeholder='Provinsi' onChange={e => setAddress({ ...address, province: e.target.value })} />
							<Input placeholder='Kabupaten/Kota' onChange={e => setAddress({ ...address, city: e.target.value })} />
							<Input placeholder='Kecamatan' onChange={e => setAddress({ ...address, suburb: e.target.value })} />
							<Input placeholder='Kelurahan' onChange={e => setAddress({ ...address, area: e.target.value })} />
							<Textarea placeholder='Alamat Lengkap' onChange={e => setAddress({ ...address, fullAddress: e.target.value })} />
							<Button colorScheme="blue" onClick={saveAddress}>{addressSaved ? "Alamat Tersimpan. Tunggu Notifikasi selanjutnya" : "Simpan Alamat"}</Button>
						</Stack>
						<ShipperForm />
					</>
					: <></>}


				{params.id === "FHFdfaoIpGKOYaOSnBwz" ? <>
					<Center>
						<SimpleGrid gap={2} columns='2' w='full'>
							<Text>Harga : </Text>
							<Text>Rp 500,000</Text>
							<Text>PPN (11%) : </Text>
							<Text>Rp 55,000</Text>
							<Text fontWeight='bold'>Total : </Text>
							<Text fontWeight='bold'>Rp 555,000</Text>
						</SimpleGrid>
					</Center>
				</> : <></>} */}
			</Box>
			{isShowingIframe &&
				<>
					<Box w='full' p='5' display='flex' flexDir='column' justifyContent='center' alignContent='center'>
						<Text fontWeight='bold' textAlign='center' mb='5'>
							You should be redirected to Payment Page, if not please click this link below:
						</Text>
						<a
							rel='noopener noreferrer' target='_blank'
							style={{ color: 'blue', textAlign: 'center' }}
							href={`https://crm.deoapp.com/payment/ticket/xendit/${value.webConfig.projectsId}/${updateData.phoneNumber}/${updateData.name}/${encodeURIComponent(updateData.formId)}`}
							onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
							onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
						>
							{`https://crm.deoapp.com/payment/ticket/xendit/${value.webConfig.projectsId}/${updateData.phoneNumber}/${updateData.name}/${encodeURIComponent(updateData.formId)}`}
						</a>
						<Text fontWeight='bold' textAlign='center' mb='5'>
							<p style={{ color: 'red' }}>WARNING!! </p>If you already do the payment, please ignore this page.
						</Text>
					</Box>

					{/* <iframe
						id="paymentPage"
						src={`https://crm.deoapp.com/payment/ticket/xendit/${value.webConfig.projectsId}/${updateData.phoneNumber}/${updateData.name}/${encodeURIComponent(updateData.formId)}`}
						title="description"
						style={{
							width: '100%',
							height: '100vh',
							border: 'none', // Optional: Removes iframe border
						}}
					/> */}
				</>
			}
		</>
	)
}

export default FormTicketMicroSite