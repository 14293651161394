import {Badge, Box, Heading, HStack, Text } from '@chakra-ui/react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CarouselComponent } from '../../Components/Galleries/Carousel/CarouselComponent'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'
import { getSingleDocumentFirebase } from '../../Api/firebaseApi'
import parse from 'html-react-parser';

function NewsViewPage() {
	const value = useContext(GlobalContext);
	const [data, setData] = useState({});
	const params = useParams();

	const getNewsDetail = useCallback(async () => {
		const result = await getSingleDocumentFirebase('news', params.id);
		if (result) {
			setData(result)
		}
	},[params.courseId]);

	useEffect(()=>{
		getNewsDetail();
	},[]);


	return (
		<Box>
			<Box mb='5' shadow='base' >
				<CarouselComponent images={[{link : "", image : data.thumbnail}]} />
				<HStack px='5' py='5'>
					<Heading size='md'>{data.title}</Heading>
				</HStack>
				<Text px='5' fontSize='md'>{data.timestamp?.toDate().toDateString()}</Text>
			</Box>
			<Box mb='5' shadow='base' px='5' pb='5'>
				<Heading fontSize='md' mb='3'>Tags</Heading>
				<HStack justifySelf='center'>
					{data.tags && data?.tags?.length > 0 ? data?.tags?.map((tag, i)=> (
						<Badge colorScheme={value.webConfig.colorScheme} key={i} borderRadius={5} fontSize={'xs'} px='2'>{tag}</Badge>
					)) : null}
				</HStack>
			</Box>
			<Box mb='5' shadow='base' px='5' pb='5'>
				{!data && data !== {} ? null : parse(data.content || "")}
			</Box>
		</Box >
	)
}

export default NewsViewPage