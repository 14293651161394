import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../Pages/Error/Error404';
// import HomeMicroSite from '../Pages/Home/HomeMicroSite';
import AuthenticationRoutes from './AuthenticationRoutes';
import CartRoutes from './CartRoutes';
import CourseRoutes from './CourseRoutes';
import NewsRoutes from './NewsRoutes';
import { RoutesAnimation } from './RoutesAnimation';
import SettingRoutes from './SettingRoutes';
import ListingRoutes from './ListingRoutes';
import HomeMicroSiteV6 from '../Pages/Home/HomeMicroSiteV6';
import TicketRoutes from './TicketRoutes';
import ProductRoutes from './ProductRoutes';
import SosmedlinkRoutes from './SosmedlinkRoutes';



function MainRoutes() {
	return (
		<Routes>
			<Route element={<RoutesAnimation />}>
				<Route path="/" element={<HomeMicroSiteV6 />} />
				<Route path="course/*" element={<CourseRoutes />} />
				<Route path="news/*" element={<NewsRoutes />} />
				<Route path="authentication/*" element={<AuthenticationRoutes />} />
				<Route path="cart/*" element={<CartRoutes />} />
				<Route path="listing/*" element={<ListingRoutes />} />
				<Route path="account/*" element={<SettingRoutes />} />
				<Route path="ticket/*" element={<TicketRoutes />} />
				<Route path="product/*" element={<ProductRoutes />} />
				<Route path="sosmedlink/*" element={<SosmedlinkRoutes />} />
				<Route path="*" element={<Error404 />} />
			</Route>
		</Routes>
	)
}

export default MainRoutes