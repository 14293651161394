import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import GlobalProvider from './Hooks/Contexts/GlobalProvider';
import * as Sentry from "@sentry/react";
// import WebConfigProvider from './Hooks/Contexts/WebConfig/WebConfigProvider';
// import WebConfig from './Hooks/Contexts/WebConfig/WebConfigProvider';

const isLocalhost = () => {
	return (
		window.location.hostname === "localhost" ||
		window.location.hostname === "127.0.0.1"
	);
};

if (!isLocalhost()) {
	Sentry.init({
		dsn: "https://a01a5e66f7034857890147a208ec3c7e@o1121849.ingest.sentry.io/4505543430963200",
		integrations: [
		  new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
		  }),
		  new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
			<GlobalProvider>
				<App />
			</GlobalProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
