import React, { useContext, useEffect, useState } from 'react';
import { 
  AspectRatio, 
  Box, 
  Heading, 
  HStack, 
  Spacer, 
  Button, 
  Text, 
  Stack, 
  AlertDialog, 
  AlertDialogOverlay, 
  AlertDialogContent, 
  AlertDialogHeader, 
  AlertDialogCloseButton, 
  AlertDialogBody, 
  AlertDialogFooter, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton, 
  ModalBody, 
  ModalFooter, 
  Divider, 
  Flex,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import parse from 'html-react-parser';
import { useNavigate, useParams } from 'react-router-dom';
import { getCollectionFirebase, getSingleDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import { PriceTag } from '../../Components/Carts/CartDrawer/PriceTag';
import { auth, db } from '../../Config/firebase';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';
import { arrayUnion, doc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import Countdown from '../../Components/Countdown/Countdown';
import moment from 'moment';
import { FaLock } from 'react-icons/fa';
import { BsCartPlus } from 'react-icons/bs';
import { getRandomString } from '../../Utils/RandomString';
import { createIpaymuPayment } from '../../Api/finpayPaymentApi';
import { generateRandomNumber } from '../../Utils/RandomNumber';
import ExpiredCourseComponent from './ExpiredCourseComponent';
import BackButtonComponent from '../../Components/Buttons/BackButtonComponent';

const MiniCourseSite = () => {
  const navigate = useNavigate();
  const params = useParams();
  const user = auth.currentUser;
  const finalRef = React.useRef(null);
  const [modalUrl, setModalUrl] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState([]);
  const [courseDetail, setCourseDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [purchased, setPurchased] = useState([]);
  const [alert, setAlert] = useState(false);
  const [timeEnd, setTimeEnd] = useState([]);
  const value = useContext(GlobalContext);
  const { handleToast } = useContext(GlobalContext);
  
  // Calculate viewport dimensions
  const headerHeight = document?.getElementById('header')?.clientHeight;
  const windowHeight = window.innerHeight;
  const contentPortion = ((windowHeight - headerHeight) / windowHeight) * 100;

  const getCourseDetail = async () => {
    try {
      const res = await getSingleDocumentFirebase('courses', params.courseId);
      setCourseDetail(res);
    } catch (error) {
      console.log(error.message, "---error when getting data course mini course");
    };
  };

  const getCustomer = async () => {
    const conditions = [
      { field: 'userId', operator: '==', value: auth?.currentUser?.uid },
      { field: 'projectId', operator: '==', value: value?.webConfig?.projectsId },
      { field: 'paymentStatus', operator: '==', value: 'PAID' },
    ];

    try {
      const res = await getCollectionFirebase('orders', { conditions }, { sortBy: null }, { limitValue: null }, { startAfterData: null });
      console.log(res, 'res');
      if (res) {
        res?.map((c) => {
          c?.orders?.map((y) => {
            if (y.id === params.courseId) {
              setPurchased(y);
              setTimeEnd(c.lastUpdated); // Store the lastUpdated timestamp here
            }
          });
        });
      }
    } catch (error) {
      console.log('Error fetching customer data:', error.message);
    }
  };

  const handleAddToCart = async () => {
    //check if user loggedin ?
    if (user) {
      const newCart = value?.cart?.carts;
      const findItemIndex = newCart?.findIndex(item => item.id === params.courseId);

      if (findItemIndex === -1 || !newCart || !findItemIndex) {
        setDoc(
          doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
          {
            createdAt: serverTimestamp(),
            carts: arrayUnion({
              id: params?.courseId || "",
              name: courseDetail?.title || "",
              price: courseDetail?.price || "",
              image: courseDetail?.thumbnail || "",
              qty: 1
            }),
          },
          { merge: true }
        ).then(() => {
          setAlert(true);
        });
      } else {
        const existingItem = newCart[findItemIndex];
        const updatedQuantity = existingItem.qty + 1;

        updateDoc(
          doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
          {
            [`newCart.${findItemIndex}.qty`]: updatedQuantity,
          }
        ).then(() => {
          handleToast(
            "Cart",
            `${courseDetail.title} successfully added to your cart`,
            "success"
          );
        });
      }
    } else {
      handleToast("Sign Up / Login", "You must have an accout to purchase this course", "warning");
      navigate("/authentication/login", { replace: true });
    }
  };

  const handleBuyNow = async (price) => {
    if (!auth?.currentUser) {
      localStorage.setItem("pendingLocation", JSON.stringify(window.location.href));
      navigate("/authentication/login");
    }
    if (value.webConfig.name === "DBrand") {
      setLoading(true);
      const ipaymuPayload = {
        companyId: "Z55P0SgE1tztgKziGsmE",
        projectId: "PQwxxgMvG8ivkxyP5sHs",
        product: [courseDetail.title?.trim()],
        qty: ['1'],
        price: [(courseDetail?.price).toString()],
        amount: (courseDetail?.price).toString(),
        returnUrl: window.location.href,
        cancelUrl: "https://your-website.com/cancel-page", 
        notifyUrl: "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/ipaymuCallbackPayment",
        referenceId: getRandomString(),
        buyerName: auth?.currentUser?.displayName || auth?.currentUser?.email?.split("@")[0],
        buyerPhone: generateRandomNumber(12),
        buyerEmail: auth?.currentUser?.email,
        va: "1179001282518873",
        apiKey: "7C003A54-B6D9-426D-AF1A-A1331D9624E8",
        target: "production",
        is_production: true
      };

      console.log("ipaymuPayload", ipaymuPayload);

      try {
        const resultIpaymu = await createIpaymuPayment(ipaymuPayload);
        console.log(resultIpaymu, "resultupaymu");

        if (resultIpaymu.data.status === true) {
          handleToast("Success", "", "success");

          window.open(resultIpaymu.data.data.Data.Url, "_blank");
          if (resultIpaymu?.data?.data?.Data?.Url) setRedirectUrl(resultIpaymu?.data?.data?.Data?.Url);

          const axiosData = {
            is_production: true,
            email: auth?.currentUser?.email,
            externalId: ipaymuPayload.referenceId,
            amount: parseInt(price),
            description: "",
            companyId: value?.webConfig?.id,
            projectId: value?.webConfig?.projectsId,
            userId: auth?.currentUser?.uid,
            module: "lms",
            mobilePhone: '+62' + generateRandomNumber(),
            orders: [{
              id: params.courseId,
              name: courseDetail?.title,
              qty: 1,
              image: courseDetail?.thumbnail
            }],
            createdAt: new Date(),
            paymentStatus: "PENDING",
            paymentMethod: "IPAYMU",
            redirectUrl: resultIpaymu?.data?.data?.Data?.Url || ""
          };

          await setDocumentFirebase('orders', axiosData?.externalId, axiosData, value?.webConfig?.projectsId);
          navigate('/cart/checkout', { state: { resultIpaymu: resultIpaymu } });

          try {
            await setDocumentFirebase('orders', axiosData?.externalId, axiosData, value?.webConfig?.projectsId);
          } catch (error) {
            console.log(error.message, "error saving order");
          }
        } else {
          console.log(resultIpaymu.data.message);
          handleToast("Failed", "", "error");
        }
      } catch (error) {
        console.log(error, 'ini error');
      }
      finally {
        setLoading(false);
        setModalUrl(true);
      }
    } else {
      handleAddToCart();
      navigate("/cart/checkout", { state: price });
    }
  };

  useEffect(() => {
    getCourseDetail();
    getCustomer();
  }, []);

  const isCourseExpired = (lastUpdatedTimestamp) => {
    const currentTime = Date.now() / 1000; // Convert to seconds
    const timeDifference = currentTime - lastUpdatedTimestamp.seconds;
    const twentyFourHoursInSeconds = 24 * 60 * 60; // 24 hours in seconds
    return timeDifference >= twentyFourHoursInSeconds;
  };

  const expired = () => {
    return (moment(new Date().valueOf())) > ((timeEnd.seconds * 1000) + (24 * 60 * 60 * 1000));
  };

  return (
    <>
      <BackButtonComponent />
      <Box
        h={`${contentPortion}vh`}
        pos={'relative'}
      >
        {isCourseExpired(timeEnd) && params.courseId === purchased?.id && value?.webConfig?.projectsId === "PQwxxgMvG8ivkxyP5sHs" ? (
          <ExpiredCourseComponent courseDetail={courseDetail} />
        ) : (
          params?.courseId === purchased?.id || courseDetail.priceType === 'free' ? (
            <Box>
              {courseDetail.sourceType === "file" ? (
                <AspectRatio ratio={16 / 9}>
                  <iframe src={courseDetail.media} title="File Preview" width="auto" height="auto"></iframe>
                </AspectRatio>
              ) : (
                <AspectRatio ratio={16 / 9} alignItems='center' justifyContent={'center'}>
                  <ReactPlayer
                    width='full'
                    height='full'
                    controls={true}
                    url={courseDetail?.media}
                    playing
                    pip
                    config={{
                      youtube: {
                        playerVars: { showinfo: 1 }
                      },
                    }}
                  />
                </AspectRatio>
              )}
            </Box>
          ) : (
            <Box>
              <AspectRatio ratio={16 / 9} position={'relative'}>
                <ReactPlayer
                  width='full'
                  height='full'
                  controls={true}
                  url={courseDetail?.media}
                  pip
                  config={{
                    youtube: {
                      playerVars: { showinfo: 1 }
                    },
                  }}
                />
              </AspectRatio>
              <Box 
                alignItems={'center'} 
                display='flex' 
                flexDirection={'column'} 
                justifyContent={'center'} 
                w={'full'} 
                aspectRatio={16 / 9} 
                bgColor='blackAlpha.800' 
                position={'absolute'} 
                top={0}
              >
                <FaLock color='white' size={40} />
                <Text color={'white'} fontWeight={500} fontSize='sm'>Please, purchase the course to unlock</Text>
              </Box>
            </Box>
          )
        )}

        <Box>
          <HStack px='5' py='5'>
            <Heading size='md'>{courseDetail?.title}</Heading>
            <Spacer />
          </HStack>
        </Box>

        {(courseDetail && courseDetail?.description) &&
          <Box mb='5' px='5' pb='5' fontSize={'md'}>
            {parse(courseDetail?.description)}
          </Box>}

        {value?.webConfig?.projectsId === "PQwxxgMvG8ivkxyP5sHs" && <>
          {((isCourseExpired(timeEnd) || params?.courseId === purchased?.id) && courseDetail?.priceType !== "free") && (
            <Stack 
              position='fixed'
              bg='white'
              bottom='10'
              p='2'
              textAlign='center'
            >
              <Countdown 
                startDate={moment(new Date()).valueOf()} 
                endDate={(timeEnd.seconds * 1000) + (24 * 60 * 60 * 1000)} 
              />
            </Stack>
          )}
        </>}

        <Box position='fixed' bottom={0} left={0} right={0} p={3} zIndex={100} width='full' bg="white" boxShadow="0 -1px 6px rgba(0,0,0,0.1)">
          {courseDetail?.priceType !== "free" && courseDetail?.price !== "free" && (
            <HStack justifyContent="space-between">
              {purchased?.id !== params?.courseId || (value?.webConfig?.projectsId === "PQwxxgMvG8ivkxyP5sHs" && expired()) ?
                <Flex w="full" justifyContent="space-between" alignItems="center">
                  <Box fontSize={'lg'} fontWeight="bold">
                    {courseDetail?.price && <PriceTag price={courseDetail?.price} />}
                  </Box>
                  <HStack spacing={3}>
                    {value?.webConfig?.projectsId !== "PQwxxgMvG8ivkxyP5sHs" &&
                      <Button 
                        leftIcon={<BsCartPlus />} 
                        size="sm" 
                        colorScheme="gray" 
                        variant="outline"
                        onClick={() => handleAddToCart()}
                      >
                        Add to Cart
                      </Button>
                    }
                    <Button
                      colorScheme={value.webConfig.colorScheme}
                      onClick={() => handleBuyNow(courseDetail?.price)}
                      isLoading={loading}
                      disabled={loading}
                      loadingText="Processing..."
                      size="sm"
                    >
                      Buy Now
                    </Button>
                  </HStack>
                </Flex>
                :
                <></>
              }
            </HStack>
          )}
        </Box>
        
        <AlertDialog
          motionPreset='slideInBottom'
          onClose={() => setAlert(false)}
          isOpen={alert}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>Cart</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              {courseDetail.title} added to your cart.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button colorScheme='red' ml={3} onClick={() => navigate('/cart')}>
                Go to My Cart
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        <Modal finalFocusRef={finalRef} isOpen={modalUrl} onClose={() => setModalUrl(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Proceed to Payment</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Heading size={'sm'}>{courseDetail.title}</Heading>
              <Divider my={3} />
              <Text>Please complete your payment here: </Text>
              <a href={`${redirectUrl}`} target="_blank" rel="noopener noreferrer"> 
                <Text color={'blue'} _hover={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  {redirectUrl}
                </Text>
              </a>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={() => setModalUrl(false)}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default MiniCourseSite;