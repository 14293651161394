import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ViewBars from "../../Components/ViewBars/ViewBars";
import { AiOutlineSearch } from "react-icons/ai";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import { clientTypesense } from "../../Api/Typesense";
import { getSingleDocumentFirebase } from "../../Api/firebaseApi";
import {
  collection,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import { MdClear } from "react-icons/md";
import { useLocation } from "react-router-dom";
import ListCardComponent from "../../Components/Cards/ListCardComponent";
import ListProductCardComponent from "../../Components/Cards/ListProductCartComponent";

const ProductMicroSite = () => {
  const [layout, setLayout] = useState("single");
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [selectedCategoryNiche, setSelectedCategoryNiche] = useState("All");
  const [isFilteringCategory, setIsFilteringCategory] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [documentCounts, setDocumentCounts] = useState(0);

  const param = useLocation();

  const bgCol = useColorModeValue("white", "gray.800");
  const fontCol = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  const value = useContext(GlobalContext);

  const observerTarget = useRef(null);

  const getDataCategory = async () => {
    try {
      const unsubscribe = await onSnapshot(
        doc(db, "categories", value?.webConfig?.projectsId),
        async (docCat) => {
          if (docCat.data()) {
            const result = await getSingleDocumentFirebase(
              `categories/${
                value?.webConfig?.projectsId
              }/${param.pathname.replace("/", "")}`,
              "data"
            );
            setCategoryList(result);
            setSelectedCategory(param.pathname.replace("/", ""));
          }
        }
      );
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const getProducts = async () => {
    let arr = [];
    try {
      const collectionRef = query(
        collection(db, "listings_product"),
        orderBy("createdAt"),
        where("projectId", "==", value?.webConfig?.projectsId),
        where("is_shipping", "==", true),
        limit(5)
      );
      // const q = query(collection(db, "listings_product"), where("projectId", "==", value?.webConfig?.projectsId));
      const querySnapshot = await getDocs(collectionRef);
      const snapshot = await getCountFromServer(collectionRef);
      setDocumentCounts(snapshot.data().count);

      querySnapshot.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      console.log("arr", arr);
      setProducts(arr);
    } catch (error) {
      console.log(error.message, "error get products");
    }
  };

  const handleSearchProducts = async (q) => {
    setSearchString(q);
    if (q?.length >= 3) {
      let arr = [];
      // setLoading(true);
      const searchParameters = {
        q: q,
        query_by: "title",
        filter_by: `projectsId:${value?.webConfig?.projectsId}`,
        sort_by: "_text_match:asc",
      };
      clientTypesense
        .collections("products")
        .documents()
        .search(searchParameters)
        .then((x) => {
          const hits = x.hits.map((x) => x.document);
          // const searchResults = q.length > 0 ? hits : [];

          hits.forEach((x) => {
            getSingleDocumentFirebase("products", x?.id)
              .then((result) => {
                arr.push(result);
                // console.log("this is the result", result)
              })
              .catch((error) => {
                console.log("error search", error.message);
              });
          });
        })
        .catch((err) => console.log(err.message));

      setTimeout(() => {
        if (arr?.length > 0) {
          setIsSearchActive(true);
          setSearchResults(arr);
        }
      }, 800);
    }
  };

  const handleLoadMore = async () => {
    setLoading(true);
    let arr = [];
    // Get the last visible document
    const lastVisible = products[products.length - 1]?.createdAt;
    // Construct a new query starting at this document,
    // get the next 25 cities.
    try {
      const next = query(
        collection(db, "listings_product"),
        orderBy("createdAt"),
        where("projectsId", "==", value?.webConfig?.projectsId),
        startAfter(lastVisible),
        limit(5)
      );
      const querySnapshot = await getDocs(next);
      querySnapshot.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      const updateData = [...products, ...arr];
      setProducts(updateData);
      setLoading(false);
    } catch (error) {
      console.log(error.message, "error next");
    }
  };

  const removeFilterCategory = () => {
    setIsFilteringCategory(false);
    setIsSearchActive(false);
    getProducts();
    getDataCategory();
  };

  useEffect(() => {
    getDataCategory();
    getProducts();
  }, []);

  return (
    <>
      <Box bg={bgCol}>
        <SimpleGrid columns="2" bgColor="brand.1" p="2">
          {/* <Text>Filter</Text> */}

          <Heading textAlign="left" size="md" color={"black"}>
            Product site
          </Heading>
          <Stack alignItems="flex-end" justifyContent="center">
            <ViewBars setLayout={setLayout} layout={layout} />
          </Stack>
          <Stack alignItems="flex-end" justifyContent="center"></Stack>
        </SimpleGrid>
        <Flex my={2} w="full">
          {/* <Input placeholder="Search videos / products here" onChange={e => console.log(e.target.value)} /> */}
          <InputGroup mx={1}>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
              children={<Icon as={AiOutlineSearch} />}
            />
            <Input
              placeholder="Enter product name"
              onChange={(e) => handleSearchProducts(e.target.value)}
              // value={searchString}
            />
            <InputRightElement
              onClick={() => setSearchString("")}
              cursor="pointer"
              _hover={{
                backgroundColor: "gray.100",
              }}
            >
              <Icon
                as={MdClear}
                color="gray.400"
                _active={{
                  transform: "scale(0.9)",
                }}
              />
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Flex>
          <Flex gap={2}>
            {isFilteringCategory && (
              <Button onClick={removeFilterCategory} variant="outline">
                Reset
              </Button>
            )}
            {categoryList?.category?.map((x, index) => {
              return (
                <Tooltip label={`${x}`} key={index}>
                  <Box
                    spacing={2}
                    borderBottomWidth={3}
                    borderColor={value?.webConfig?.brand[0]}
                    py={2}
                    px={2}
                    borderRadius="md"
                    shadow="md"
                  >
                    <Text
                      mx="2"
                      maxW="150px"
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      // onClick={() => handleCategoryFilter(x)}
                      textTransform="capitalize"
                      fontWeight={
                        selectedCategoryNiche === x?.toLowerCase()
                          ? 500
                          : "normal"
                      }
                      color={
                        selectedCategoryNiche === x?.toLowerCase()
                          ? "blue.500"
                          : "gray.600"
                      }
                      cursor="pointer"
                    >
                      {x}
                    </Text>
                  </Box>
                </Tooltip>
              );
            })}
          </Flex>
        </Flex>

        {products?.length > 0 ? (
          <>
            <SimpleGrid
              // columns={[1, null, 2]}
              columns={layout === "single" ? 1 : 2}
              my="2"
            >
              {isSearchActive &&
                searchResults?.map((x, i) => {
                  return (
                    <Box key={i}>
                      <ListProductCardComponent
                        bgCol={bgCol}
                        fontCol={fontCol}
                        data={x}
                      />
                    </Box>
                  );
                })}
              {!isSearchActive ? (
                products?.map((x, i) => {
                  return (
                    <Box key={i}>
                      <ListProductCardComponent
                        bgCol={bgCol}
                        fontCol={fontCol}
                        data={x}
                      />
                    </Box>
                  );
                })
              ) : (
                <></>
              )}
            </SimpleGrid>
            {!isFilteringCategory && documentCounts > 5 ? (
              <Center>
                <Button
                  color="gray"
                  size="sm"
                  variant="ghost"
                  onClick={handleLoadMore}
                >
                  See More Products
                </Button>
              </Center>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Box
            w="100%"
            bg={bgCol}
            h="70vh"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text
              textAlign={"center"}
              fontWeight={"sm"}
              color={"gray.500"}
              mt="5"
            >
              No products available
            </Text>
          </Box>
        )}
        <Box ref={observerTarget}></Box>
        {/* {loading && <Center>Loading....</Center>} */}
      </Box>
    </>
  );
};

export default ProductMicroSite;
