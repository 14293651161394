import {
  Box,
  Button,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  HStack,
  Grid,
  Text,
  Stack,
  AspectRatio,
  useColorModeValue,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
  ModalFooter,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../Config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from "../../Api/firebaseApi";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import { FiLock } from "react-icons/fi";
import { FaPlay } from "react-icons/fa";
import { PriceTag } from "../../Components/Carts/CartDrawer/PriceTag";
import MiniCourseSite from "./MiniCourseSite";
import PromptListPage from "../Prompts/PromptListPage";
import { BsCartPlus } from "react-icons/bs";

const SingleCourseSite = () => {
  const params = useParams();
  const user = auth.currentUser;
  const value = useContext(GlobalContext);
  const { handleToast } = useContext(GlobalContext);
  const finalRef = useRef(null);
  const headerHeight = document?.getElementById("header")?.clientHeight;
  const windowHeight = window.innerHeight;
  const childPortion = ((windowHeight - headerHeight) / windowHeight) * 100;

  const bgCol = useColorModeValue("white", "gray.800");
  const fontCol = useColorModeValue("blackAlpha.700", "whiteAlpha.700");

  const [noOfLines, setNoOfLines] = useState(5);
  const [courseDetail, setCourseDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [lessons, setLessons] = useState(null);
  const [purchased, setPurchased] = useState([]);
  const [modalUrl, setModalUrl] = useState(false);

  const navigate = useNavigate();

  const getCourseDetail = async () => {
    const res = await getSingleDocumentFirebase("courses", params.courseId);
    if (res) {
      setCourseDetail(res);
      console.log(res, 'xxx');
    }
  };

  const getLessons = async () => {
    // const res = await getCollectionWhereFirebase(`courses/${params.courseId}/lessons`, 'section', '==', state.data.section)
    const res = await getCollectionFirebase(
      `courses/${params.courseId}/lessons`,
      { conditions: [] },
      { sortBy: null },
      { limitValue: null },
      { startAfterData: null }
    );
    if (res) {
      //   setData(res.filter(item => item.section === params.sectionTitle));
      setLessons(res);
    }
  };

  const getCustomer = async () => {
    const conditions = [
      { field: "userId", operator: "==", value: auth?.currentUser?.uid },
      {
        field: "projectId",
        operator: "==",
        value: value?.webConfig?.projectsId,
      },
      { field: "paymentStatus", operator: "==", value: "PAID" },
    ];

    try {
      const res = await getCollectionFirebase(
        "orders",
        { conditions },
        { sortBy: null },
        { limitValue: null },
        { startAfterData: null }
      );
      if (res) {
        // console.log(params.courseId, "params")

        res?.map((c) => {
          c?.orders?.map((y) => {
            if (y.id === params.courseId) {
              setPurchased(y);
            }
          });
        });
      }

      // Set the updated res to the state variable 'purchased'
    } catch (error) {
      console.log("Error fetching customer data:", error.message);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  const handleAddToCart = async () => {
    //check if user loggedin ?
    if (user) {
      const newCart = value?.cart?.carts;
      const findItemIndex = newCart?.findIndex(
        (item) => item.id === params.courseId
      );

      if (findItemIndex === -1 || !newCart || !findItemIndex) {
        setDoc(
          doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
          {
            carts: arrayUnion({
              id: params.courseId,
              name: courseDetail.title,
              price: courseDetail.price,
              image: courseDetail.thumbnail,
              // link: courseDetail.link,
              qty: 1,
            }),
          },
          { merge: true }
        ).then(() => {
          setAlert(true);
        });
      } else {
        const existingItem = newCart[findItemIndex];
        const updatedQuantity = existingItem.qty + 1;

        updateDoc(
          doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
          {
            [`newCart.${findItemIndex}.qty`]: updatedQuantity,
          }
        ).then(() => {
          handleToast(
            "Cart",
            `${courseDetail.title} successfully added to your cart`,
            "success"
          );
        });

        // setDoc(
        // 	doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
        // 	// {
        // 	// 	carts: arrayUnion({
        // 	// 		id: params.courseId,
        // 	// 		name: courseDetail.title,
        // 	// 		price: courseDetail.price,
        // 	// 		image: courseDetail.thumbnail,
        // 	// 		// link: courseDetail.link,
        // 	// 		qty: value?.cart?.carts[newCart]?.qty + 1
        // 	// 	}),
        // 	// },
        // 	{
        // 		carts: arrayRemove({
        // 			id: params.courseId,
        // 			name: courseDetail.title,
        // 			price: courseDetail.price,
        // 			image: courseDetail.thumbnail,
        // 			// link: courseDetail.link,
        // 			qty: value?.cart?.carts[newCart]
        // 		})
        // 	},
        // 	{ merge: true }
        // ).then(() => {
        // 	setDoc(
        // 		doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
        // 		{
        // 			carts: arrayUnion({
        // 				id: params.courseId,
        // 				name: courseDetail.title,
        // 				price: courseDetail.price,
        // 				image: courseDetail.thumbnail,
        // 				// link: courseDetail.link,
        // 				qty: value?.cart?.carts[newCart]?.qty + 1
        // 			}),
        // 		}, { merge: true }).then(() => {
        // 			handleToast(
        // 				"Keranjang",
        // 				`Berhasil menambahkan ${courseDetail.title} ke keranjang kamu`,
        // 				"success"
        // 			);
        // 		})
        // })
      }
    } else {
      handleToast(
        "Sign Up / Login",
        "You must have an accout to purchase this course",
        "warning"
      );
      navigate("/authentication/login", { replace: true });
    }
  };

  const handleBuyNow = async (price) => {
    if (!auth?.currentUser) {
      // localStorage.setItem("pendingCourse", courseDetail?.id);
      localStorage.setItem(
        "pendingLocation",
        JSON.stringify(window.location.href)
      );
      handleToast(
        "Please Login / Signup",
        "Please login to Purchase Course.",
        "warning"
      );
      navigate("/authentication/login");
    } else {
      handleAddToCart();

      // const detailPayment = {
      // 	product: [{name: courseDetail.title,
      // 		price: courseDetail.price,
      // 		image: courseDetail.thumbnail,
      // 		// link: courseDetail.link,
      // 		qty: 1}],
      // 	price: price
      // }
      navigate("/cart/checkout", { state: price });
      // navigate("/cart/checkout", { state: detailPayment });
    }
    // onOpen();
  };

  useEffect(() => {
    getCourseDetail();
    getLessons();
    getCustomer();
  }, []);

  if (courseDetail?.courseType === "full_course")
    return (
      <Box h={`${childPortion}%`} pos={"relative"} overflow={"hidden"}>
        <Box>
          <Box>
            <AspectRatio ratio={16 / 9} id="videoPlayer">
              <ReactPlayer
                width="full"
                height="full"
                controls={true}
                playing
                playIcon={
                  <IconButton
                    rounded="full"
                    width={50}
                    height={50}
                    icon={<FaPlay />}
                  />
                }
                url={
                  courseDetail?.videoThumbnail
                    ? courseDetail?.videoThumbnail
                    : "https://www.youtube.com/watch?v=D0UnqGm_miA&ab_channel=SolutionsForAll"
                }
              />
            </AspectRatio>

            {courseDetail?.description ? (
              <Box mx="5">
                <Box mt={5} noOfLines={noOfLines} fontSize={14}>
                  {parse(courseDetail?.description)}
                </Box>
                <Flex justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      if (noOfLines === 5) setNoOfLines(null);
                      if (noOfLines === null) setNoOfLines(5);
                    }}
                    size="xs"
                    variant="ghost"
                  >
                    {noOfLines === 5 ? "Read more..." : "collapse"}
                  </Button>
                </Flex>
              </Box>
            ) : null}

            {/* {courseDetail?.sections ? (
                    <SimpleGrid columns={1}>
                    {courseDetail?.sections?.map((x, i) => (
                        <SectionCardComponent
                        key={i}
                        data={x}
                        type='section'
                        cursor='pointer'
                                _hover={{
                                    transform: 'scale(1.04)',
                                    transition: "60ms linear"
                                }}
                                />
                        ))}
                        </SimpleGrid>
                ) : (
                    <Center>
                    <Spinner />
                    </Center>
                    )}
                    
                <Modals isOpen={isOpen} onClose={onClose} data={data} />
            )} */}

            <Box mx={4} my={5}>
              <Accordion allowToggle h={350} overflowY={"scroll"}>
                {courseDetail?.sections?.map((section, i) => {
                  const numberOfLessons = lessons?.filter(
                    (item) => item.section === section.title
                  )?.length;
                  return (
                    <AccordionItem key={i} bg={bgCol} my={2}>
                      <h2>
                        <AccordionButton>
                          <Stack gap={2} as="span" flex="1" textAlign="left">
                            <Heading size="xs">{section.title}</Heading>
                            <HStack>
                              <Text color={fontCol} fontSize={12} size="md">
                                {numberOfLessons} lessons available
                              </Text>
                            </HStack>
                          </Stack>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Grid gap={2}>
                          {lessons
                            ?.filter((item) => item.section === section.title)
                            .sort(
                              (a, b) =>
                                a?.createdAt?.seconds - b?.createdAt?.seconds
                            )
                            ?.map((lesson, idx) => (
                              <Box bg="blackAlpha.50" p={2} key={idx}>
                                <Flex justifyContent="space-between">
                                  <Text
                                    fontSize={13}
                                    fontWeight={500}
                                    color={fontCol}
                                  >
                                    {lesson?.title}
                                  </Text>
                                  {courseDetail?.priceType === "free" ||
                                  purchased?.id === params?.courseId ? (
                                    <Button
                                      size="sm"
                                      variant="outline"
                                      colorScheme={
                                        value?.webConfig?.colorScheme
                                      }
                                      onClick={() =>
                                        navigate(`lesson/${lesson.id}`)
                                      }
                                    >
                                      Review
                                    </Button>
                                  ) : (
                                    <FiLock />
                                  )}
                                </Flex>
                              </Box>
                            ))}
                        </Grid>
                      </AccordionPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </Box>
          </Box>
        </Box>

        <AlertDialog
          motionPreset="slideInBottom"
          // leastDestructiveRef={cancelRef}
          onClose={() => setAlert(false)}
          isOpen={alert}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>Cart</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              {courseDetail?.title} added to your cart.
            </AlertDialogBody>
            <AlertDialogFooter>
              {/* <Button ref={cancelRef} onClick={onClose}>
                        No
                        </Button> */}
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => navigate("/cart")}
              >
                Go to My Cart
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        <Modal
          finalFocusRef={finalRef}
          isOpen={modalUrl}
          onClose={() => setModalUrl(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Preceed to Payment</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Heading size={"sm"}>{courseDetail.title}</Heading>
              <Divider my={3} />
              <Text>Please complete your payment here : </Text>
              {/* <a href={`${redirectUrl}`}> <Text color={'blue'} _hover={{ cursor: 'pointer' }}>{redirectUrl}</Text></a> */}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setModalUrl(false)}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/* {console.log(courseDetail)} */}

        <Box
          position="absolute"
          bg="white"
          p={2}
          bottom={0}
          right={0}
          zIndex={100}
          width="full"
        >
          {courseDetail?.priceType !== "free" &&
            courseDetail?.price !== "free" && (
              <HStack gap="5">
                {purchased?.id !== params?.courseId ? (
                  <Flex
                    w="full"
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box fontSize={"lg"} w={"25%"}>
                      <PriceTag price={courseDetail.price} />
                    </Box>
                    <HStack>
                      <HStack
                        bg="gray.100"
                        p={2}
                        borderRadius={5}
                        _hover={{ cursor: "pointer" }}
                        onClick={() => handleAddToCart()}
                      >
                        <BsCartPlus size={"20px"} />
                        <Text fontSize={"12"}>Add to Cart</Text>
                      </HStack>
                      <Button
                        colorScheme={value.webConfig.colorScheme}
                        onClick={() => handleBuyNow(courseDetail?.price)}
                        isLoading={loading}
                        disabled={loading}
                        loadingText="Processing..."
                        fontSize={"12"}
                      >
                        Buy Now
                      </Button>
                    </HStack>
                  </Flex>
                ) : (
                  <></>
                )}
              </HStack>
            )}
        </Box>
      </Box>
    );
  else if (courseDetail?.courseType === "mini_course")
    return <MiniCourseSite />;
  else return <PromptListPage />;
};

export default SingleCourseSite;
