import { Box, Heading, Spacer, Text, Image, VStack, Badge, Flex, Skeleton } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function ListCardComponent(props) {
	const x = props.data;
	const col = props.bgCol
	const fontCol = props.fontCol
	// const [isLoaded, setIsLoaded] = useState(false);

	// const handleLoaded = () => {
	// 	setIsLoaded(true)
	// 	// console.log("image loaded")
	// };

	return (
		<Link to={`${x?.id}`}>
			<VStack m='2' shadow='md' borderRadius='md' mx={2} my={2} bg={col} maxH='350px'>
				{/* {!isLoaded ? <Skeleton w='full' h='200px' /> : */}
					<Image borderTopRadius={10} objectFit="contain" w='full' h='200px'
						// src='https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:705/https://cdn.filestackcontent.com/bLy3JtIoQ8y8PDs4tFem'
						src={x?.thumbnail || x?.image}
						alt={x?.title}
					/>
				{/* } */}
				<VStack>
					<Box m={4} minH='100px'>
						<Flex justify='space-between' align='center'>
							<Heading fontSize='md'>{x?.title}</Heading>
							{props?.data?.tags ? props?.data?.tags?.map((item, i) => (
								<Badge key={i} mt='1' colorScheme='blue' h='fit-content'>{item}</Badge>
							)) : <></>}

						</Flex>
						<Flex justify='space-between' align='center' my={2}>
							<Text fontSize='sm' color={fontCol} noOfLines={3}>{x?.description}</Text>
							{/* <Text textAlign='end'>Rp{x?.price}</Text> */}

						</Flex>

					</Box>
					<Spacer />
					{/* <Button justifySelf='flex-end' w='full' colorScheme={value.webConfig.colorScheme}>View</Button> */}
				</VStack>
			</VStack>

		</Link>

	)
}

export default ListCardComponent