import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';
import { getCollectionFirebase } from '../../Api/firebaseApi';
import TicketCard from "../../Components/Cards/TicketCard";


const TicketMicroSite = () => {
     const [data, setData] = useState([]);
     const values = useContext(GlobalContext);

     const getData = async () => {
          const conditions = [{
               field: "projectId",
               operator: "==",
               value: values?.webConfig?.projectsId
          }];
          const sortBy = null;
          const limitValue = null;
          // console.log(conditions)

          try {
               const res = await getCollectionFirebase('tickets', { conditions }, { sortBy }, { limitValue }, { startAfterData: null });
               setData(res);
          } catch (error) {
               console.log(error.message, "")
          };
     }


     useEffect(() => {
          getData()
          return () => {
               setData()
          }
     }, []);

     return (
          <Box h='100vh'>
               <SimpleGrid columns='3' bgColor='brand.1' p='2'>
                    <Heading size="md" color={'gray.600'}>Events</Heading>
               </SimpleGrid>

               {data?.length > 0 &&
                    <SimpleGrid columns={2} gap='2' mx='1' pt='5'>
                         {/* {dummyData?.map((item, index) => (
                         <TicketCard item={item} key={index} />
                    ))} */}
                         {data?.length > 0 ?
                              data?.map((item, index) => (
                                   <TicketCard item={item} key={index} />
                              ))
                              : <></>}
                    </SimpleGrid>}

               {data?.length === 0 &&
                    <Box w="full" h="70vh" display="flex" flexDir='row' justifyContent='center' alignItems="center">
                         <Text textAlign={'center'} fontWeight={'sm'} color={'gray.500'} mt='5'>There is no upcoming events</Text>
                    </Box>}

          </Box >
     )
}

export default TicketMicroSite