/* eslint-disable no-unused-expressions */
import {
  Button,
  Center,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { CartItem } from "../../Components/Carts/CartDrawer/CartItem";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import {
  deleteDocumentFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from "../../Api/firebaseApi";
import { PriceTag } from "../../Components/Carts/CartDrawer/PriceTag";
import { useNavigate } from "react-router";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { auth } from "../../Config/firebase";
import { FiArrowLeft } from "react-icons/fi";
import { createIpaymuPayment } from "../../Api/finpayPaymentApi";
import { getRandomString } from "../../Utils/RandomString";
import { generateRandomNumber } from "../../Utils/RandomNumber";

function CartMicroSite() {
  const { handleToast, setOrder, order } = useContext(GlobalContext);
  const value = useContext(GlobalContext);
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const [isChecked, setIsChecked] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productPayment, setProductPayment] = useState({});

  const [inputQty, setInputQty] = useState(1);
  const [newData, setNewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [totalPrice, setTotalPrice] = useState(0);
  // const { user } = auth.currentUser;
  let price = 0;

  const getData = async () => {
    const res = await getSingleDocumentFirebase(
      "carts",
      `${value?.webConfig?.projectsId}-${value?.uid}`
    );
    setData(res);
  };

  console.log(data, "ini data");

  const handleCheckout = async (option, totalPrice) => {
    if (option === "xendit") {
      if (value?.auth && value?.cart?.carts.length > 0) {
        return navigate("/cart/checkout", {
          state: { product: selectedProduct, total: totalPrice, qty: inputQty },
        });
      } else if (value?.auth && value?.cart?.carts.length === 0) {
        handleToast("Oops!", `Nothing left in cart`, "error");
      }
    } else if (option === "finpay") {
      // ////PLEASE NOTE: THIS IS FOR FINPAY
      // ////DATA STRUCTURE IS THE FOLLOWING:
      // const urlWithoutHttp = window.location.href.replace("https://", "").replace("http://", "");
      // let descriptionString = '';
      // value?.cart?.carts?.forEach(item => {
      // 	descriptionString += item?.name + ", "
      // })
      // const axiosData = {
      // 	is_production: true,
      // 	email: auth?.currentUser?.email,
      // 	externalId: getRandomString(), //buat order document dengan id yg sama
      // 	amount: parseInt(totalPrice),
      // 	description: descriptionString || "",
      // 	companyId: value?.webConfig?.id,
      // 	projectId: value?.webConfig?.projectsId,
      // 	userId: auth?.currentUser?.uid,
      // 	module: "lms",
      // 	mobilePhone: '+62' + generateRandomNumber(),
      // 	// redirectUrl: `https://${urlWithoutHttp.split(".")[0]}.deoapp.site`,
      // 	lead_id: "01923193-fAQRgF2cfpK6PdB5"
      // };
      // if (auth?.currentUser?.displayName) {
      // 	const name = auth?.currentUser?.displayName.split(' ')
      // 	axiosData.firstName = name[0]
      // 	if (name.length > 1 && name[1] !== '') {
      // 		axiosData.lastName = name[1]
      // 	} else {
      // 		axiosData.lastName = name[0]
      // 	}
      // } else {
      // 	const name = auth.currentUser.email.split('@')
      // 	axiosData.firstName = name[0]
      // 	axiosData.lastName = name[0]
      // }
      // if (auth?.currentUser?.phoneNumber) {
      // 	axiosData.mobilePhone = '+62' + auth?.currentUser?.phoneNumber.slice(1)
      // } else {
      // 	const mobile = '+6285775033279'
      // 	axiosData.mobilePhone = mobile
      // }
      // try {
      // 	setLoading(true);
      // 	console.log({ axiosData });
      // 	const res = await finpayCreatePayment(axiosData);
      // 	console.log(res, "----response create finpay payment");
      // 	if (res?.data?.status) {
      // 		window.open(res?.data?.data?.redirectUrl);
      // 		setRedirectUrl(res?.data?.data?.redirectUrl);
      // 		const updateData = {
      // 			...axiosData,
      // 			orders: [...value?.cart?.carts],
      // 			createdAt: new Date(),
      // 			module: "lms",
      // 			paymentStatus: "PENDING",
      // 			paymentMethod: "FINPAY",
      // 			redirectUrl: res?.data?.data?.redirectUrl
      // 		};
      // 		try {
      // 			await setDocumentFirebase('orders', axiosData?.externalId, updateData, value?.webConfig?.projectsId);
      // 		} catch (error) {
      // 			console.log(error.message, "error saving order")
      // 		}
      // 		setLoading(false);
      // 		setIsSubmitted(true)
      // 	};
      // } catch (error) {
      // 	console.log(error);
      // } finally {
      // 	setLoading(false);
      // }
    }
  };


  const handleSettingCheckout = async (price) => {
    if (value.webConfig.name === "DBrand") {
      setLoading(true);
      const ipaymuPayload = {
        companyId: "Z55P0SgE1tztgKziGsmE",
        projectId: "PQwxxgMvG8ivkxyP5sHs",
        product: value?.cart?.carts?.map((x) => x.name?.trim()),
        qty: value?.cart?.carts?.map((x) => x.qty.toString()),
        price: value?.cart?.carts?.map((x) => x.price.toString()),
        amount: price.toString(),
        returnUrl: "https://bisabikinbrand1.deoapp.site", //your thank you page url
        cancelUrl: "https://your-website.com/cancel-page", // your cancel page url
        notifyUrl:
          "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/ipaymuCallbackPayment",
        referenceId: getRandomString(), // your reference id or transaction id
        buyerName: auth?.currentUser?.displayName, // optional
        buyerPhone: generateRandomNumber(12), // optional
        buyerEmail: auth?.currentUser?.email, // optional
        va: "1179001282518873",
        apiKey: "7C003A54-B6D9-426D-AF1A-A1331D9624E8",
        target: "production",
      };
      // console.log(ipaymuPayload)

      try {
        const resultIpaymu = await createIpaymuPayment(ipaymuPayload);
        console.log(resultIpaymu, "resultupaymu");

        if (resultIpaymu.data.status === true) {
          window.open(resultIpaymu.data.data.Data.Url, "_blank");
          setRedirectUrl(resultIpaymu.data.data.Data.Url);

          const axiosData = {
            is_production: true,
            email: auth?.currentUser?.email,
            externalId: ipaymuPayload.referenceId, //buat order document dengan id yg sama
            amount: parseInt(price),
            description: "",
            companyId: value?.webConfig?.id,
            projectId: value?.webConfig?.projectsId,
            userId: auth?.currentUser?.uid,
            module: "lms",
            mobilePhone: "+62" + generateRandomNumber(),
            lead_id: "01923193-fAQRgF2cfpK6PdB5",
            orders: [...value?.cart?.carts],
            createdAt: new Date(),
            paymentStatus: "PENDING",
            paymentMethod: "IPAYMU",
            redirectUrl: resultIpaymu?.data?.data?.Data?.Url || "",
          };

          try {
            await setDocumentFirebase(
              "orders",
              axiosData?.externalId,
              axiosData,
              value?.webConfig?.projectsId
            );
            await deleteDocumentFirebase(
              "carts",
              `${value?.webConfig?.projectsId}-${value?.uid}`
            );
            setIsSubmitted(true);
          } catch (error) {
            console.log(error.message, "error saving order");
          }
        } else {
          console.log(resultIpaymu.data.message);
        }
      } catch (error) {
        console.log(error, "ini error");
      } finally {
        setLoading(false);
        setTimeout(() => {
          window.open(redirectUrl);
        }, 2000);
      }
    } else {
      // onOpen();
      const totalPrice = selectedProduct.reduce(
        (total, product) => total + product.price * product.qty,
        0
      );

      const payment = {
        products: selectedProduct,
        total: totalPrice,
      };

      console.log(totalPrice, "totallll");

      //   setTotalPrice(price);
      setOrder(payment);
      console.log(order);

      handleCheckout("xendit", totalPrice);

      console.log(totalPrice, "ooooo");
    }
  };
  console.log(productPayment, "ppppp");

  const handleCheckbox = (data) => {
    setIsChecked((prev) => !prev);

    if (selectedProduct.some((item) => item.id === data.id)) {
      setSelectedProduct(selectedProduct.filter((item) => item.id !== data.id));
    } else {
      setSelectedProduct([...selectedProduct, data]);
    }
  };

  //   const handleCheckbox = (index) => {
  //     // Create a copy of the isChecked array
  //     const updatedIsChecked = [...isChecked];

  //     // Toggle the checkbox state for the specific item at the given index
  //     updatedIsChecked[index] = !updatedIsChecked[index];

  //     // Update the state with the new isChecked array
  //     setIsChecked(updatedIsChecked);
  //   };

  console.log(isChecked);

  useEffect(() => {
    getData();

    return () => {
      setData({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSubmitted)
    return (
      <>
        <Stack position="relative" h="83vh" maxW="2xl">
          <Stack height="full" spacing="2" overflowY="auto">
            <HStack p="2" bgColor="brand.1">
              <AiOutlineShoppingCart />
              <Heading size="sm" color={"black"}>
                My Cart
              </Heading>
              <Spacer />
              <Heading size="xs" color={"black"}>
                ({data?.carts?.length ?? 0} items)
              </Heading>
            </HStack>
            <Stack spacing={{ base: "8", md: "10" }} p="2">
              {data?.carts?.length > 0 ? (
                <SimpleGrid columns={1} spacing={3}>
                  {data
                    ? data?.carts.map((item, index) => {
                      selectedProduct.length === 0
                        ? price === 0
                        : //   : (price += item.price * inputQty);
                        (price =
                          selectedProduct.reduce(
                            (a, b) => parseInt(a) + parseInt(b.price),
                            0
                          ) *
                          selectedProduct.reduce(
                            (a, b) => parseInt(a) + parseInt(b.qty),
                            0
                          ));

                      console.log(price);
                      return (
                        <CartItem
                          key={item.id}
                          getData={getData}
                          setData={setData}
                          selectedProduct={selectedProduct}
                          inputQty={inputQty}
                          setInputQty={setInputQty}
                          // setNewData={setNewData}
                          newData={newData}
                          index={index}
                          isChecked={isChecked}
                          // setIsChecked={(isChecked) => handleCheckbox(index)}
                          handleCheckbox={handleCheckbox}
                          {...item}
                        />
                      );
                    })
                    : null}
                </SimpleGrid>
              ) : (
                <Center>
                  <Heading size="sm">
                    You don't have anything in your cart
                  </Heading>
                </Center>
              )}
            </Stack>
          </Stack>

          {data && (
            <Stack
              borderTopWidth="1px"
              px={{
                base: "6",
                md: "10",
              }}
              py="4"
              spacing="5"
              position="absolute"
              w="full"
              bottom={0}
            >
              <Stack>
                <HStack fontSize="xl" fontWeight="semibold">
                  <Text flex="1">Subtotal:</Text>
                  <PriceTag price={price} currency={"IDR"} />
                </HStack>
              </Stack>
              <Button
                pos="sticky"
                isLoading={loading}
                onClick={() => handleSettingCheckout(price)}
                bottom={0}
                colorScheme={value.webConfig.colorScheme}
                size="lg"
                fontSize="md"
              >
                Checkout
              </Button>
            </Stack>
          )}
        </Stack>
      </>
    );
  else
    return (
      <>
        <HStack onClick={() => navigate(-1)} cursor="pointer" mt={5}>
          <FiArrowLeft fontSize="25px" />
        </HStack>
        <Box
          h="80vh"
          w="full"
          p="5"
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignContent="center"
        >
          <Text fontWeight="bold" textAlign="center" mb="5">
            You should be redirected to Payment Page, if not please click this
            link below:
          </Text>
          <a
            href={`${redirectUrl}`}
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: "blue", textAlign: "center" }}
            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          >
            {redirectUrl}
          </a>
          {/* <Text textAlign={'center'} mt='5'>You can check your payment status here</Text> */}
        </Box>
      </>
    );
}

export default CartMicroSite;
