import React, { useState, useContext } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Container,
  VStack,
  FormErrorMessage,
  Divider,
  useToast,
} from '@chakra-ui/react';
import { FiEye, FiEyeOff, FiUser, FiMail, FiLock, FiPhone } from 'react-icons/fi';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../Config/firebase';
import { doc, setDoc } from 'firebase/firestore';

function SignUpPage() {
  // States
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  
  // Hooks
  const value = useContext(GlobalContext);
  const navigate = useNavigate();
  const toast = useToast();
  const pendingLocation = localStorage.getItem('pendingLocation');
  
  // Colors
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const primaryColor = value?.webConfig?.colorScheme || 'blue';

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    
    if (!data.firstName?.trim()) {
      newErrors.firstName = 'First name is required';
    }
    
    if (!data.lastName?.trim()) {
      newErrors.lastName = 'Last name is required';
    }
    
    if (!data.phone?.trim()) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10,15}$/.test(data.phone)) {
      newErrors.phone = 'Invalid phone number format';
    }
    
    if (!data.email?.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = 'Invalid email format';
    }
    
    if (!data.password?.trim()) {
      newErrors.password = 'Password is required';
    } else if (data.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Sign up handler
  const handleSignUp = () => {
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
        
        // Create user document
        setDoc(doc(db, "users", user.uid), {
          email: data.email,
          name: `${data.firstName} ${data.lastName}`.trim(),
          owner: [],
          projects: [],
          users: [],
          phone: data.phone,
          updatedBy: user.uid,
          updatedAt: new Date(),
        }).then(() => {
          // Create customer document
          return setDoc(doc(db, 'customers', `${value?.webConfig?.companiesId}-${user.uid}`), {
            companiesId: value?.webConfig?.companiesId,
            name: `${data.firstName} ${data.lastName}`.trim(),
            purchases: [],
            uid: user.uid
          });
        }).then(() => {
          // Show success toast
          if (value.handleToast) {
            value.handleToast('Success', 'Your account has been created successfully', 'success');
          } else {
            toast({
              title: 'Account created',
              description: "Your account has been created successfully",
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top'
            });
          }
          
          // Handle navigation
          if (!pendingLocation) {
            navigate('/');
            return;
          }
          
          // Handle pending location redirects
          try {
            const cleanedUrl = JSON.parse(pendingLocation)
              ?.replace("digitalmarketing.localhost:3000", "")
              ?.replace("digitalmarketing.deoapp.site", "")
              ?.replace("http://digitalmarketing.deoapp.site", "")
              ?.replace("https://digitalmarketing.deoapp.site", "");
              
            window.location.href = cleanedUrl;
          } catch (error) {
            console.error('Error parsing pending location:', error);
            navigate('/');
          }
        });
      })
      .catch((error) => {
        if (value.handleToast) {
          value.handleToast(`${error.code}`, `${error.message}`, 'error');
        } else {
          toast({
            title: error.code,
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top'
          });
        }
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container maxW="lg" py={{ base: 8, md: 12 }}>
      <VStack spacing={6} align="stretch">
        <Box textAlign="center">
          <Heading 
            size={{ base: 'lg', md: 'xl' }}
            fontWeight="bold"
            color={`${primaryColor}.600`}
            mb={3}
          >
            Create your account
          </Heading>
          <Text color="gray.600">
            Join our community and start exploring
          </Text>
        </Box>
        
        <Box
          rounded="lg"
          bg={bgColor}
          boxShadow="md"
          p={{ base: 6, md: 8 }}
          borderWidth="1px"
          borderColor={borderColor}
        >
          <VStack spacing={5}>
            <HStack spacing={4} w="full">
              <FormControl isRequired isInvalid={errors.firstName}>
                <FormLabel>First Name</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="John"
                    value={data.firstName}
                    onChange={e => setData({ ...data, firstName: e.target.value })}
                    focusBorderColor={`${primaryColor}.500`}
                  />
                </InputGroup>
                {errors.firstName && (
                  <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isRequired isInvalid={errors.lastName}>
                <FormLabel>Last Name</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Doe"
                    value={data.lastName}
                    onChange={e => setData({ ...data, lastName: e.target.value })}
                    focusBorderColor={`${primaryColor}.500`}
                  />
                </InputGroup>
                {errors.lastName && (
                  <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                )}
              </FormControl>
            </HStack>

            <FormControl isRequired isInvalid={errors.phone}>
              <FormLabel>Phone Number</FormLabel>
              <InputGroup>
                <Input
                  type="tel"
                  placeholder="Your phone number"
                  value={data.phone}
                  onChange={e => setData({ ...data, phone: e.target.value })}
                  focusBorderColor={`${primaryColor}.500`}
                />
              </InputGroup>
              {errors.phone && (
                <FormErrorMessage>{errors.phone}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel>Email Address</FormLabel>
              <InputGroup>
                <Input
                  type="email"
                  placeholder="your-email@example.com"
                  value={data.email}
                  onChange={e => setData({ ...data, email: e.target.value })}
                  focusBorderColor={`${primaryColor}.500`}
                />
              </InputGroup>
              {errors.email && (
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.password}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Create a strong password"
                  value={data.password}
                  onChange={e => setData({ ...data, password: e.target.value })}
                  focusBorderColor={`${primaryColor}.500`}
                />
                <InputRightElement>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? "Hide password" : "Show password"}
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.password && (
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              )}
            </FormControl>
            
            <Button
              w="full"
              mt={4}
              size="lg"
              colorScheme={primaryColor}
              isLoading={loading}
              isDisabled={loading}
              loadingText="Creating Account..."
              onClick={handleSignUp}
              py={6}
            >
              Sign Up
            </Button>
          </VStack>
          
          <Divider my={6} />
          
          <Box textAlign="center">
            <Text color="gray.600">
              Already have an account?{' '}
              <Link to="/authentication/login">
                <Button 
                  variant="link" 
                  colorScheme={primaryColor} 
                  fontWeight="semibold"
                >
                  Log In
                </Button>
              </Link>
            </Text>
          </Box>
        </Box>
      </VStack>
    </Container>
  );
}

export default SignUpPage;