import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Heading,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Flex,
  Divider,
  useClipboard,
  Image,
  useDisclosure,
  VStack,
  Grid,
  useToast,
  HStack,
  FormControl,
  Input,
  Textarea,
  Select,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import { PriceTag } from "../../Components/Carts/CartDrawer/PriceTag";
import { FiArrowLeft, FiCopy } from "react-icons/fi";
import { CheckoutDrawerComponent } from "../../Components/Carts/CheckoutDrawerComponent";
import axios from "axios";
import { auth } from "../../Config/firebase";
import { banks } from "../../Constants/constants";
import moment from "moment";
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from "../../Api/firebaseApi";
import { getRandomString } from "../../Utils/RandomString";
import { removeSpecialCharacters } from "../../Utils/StringUtils";
import { formatFrice } from "../../Utils/NumberUtils";
import ShippingRajaOngkir from "../../Components/Forms/ShippingRajaOngkir";

function MicroCheckoutPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  const values = useContext(GlobalContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onCopy, setValue, hasCopied } = useClipboard("");

  const [formFields, setFormFields] = useState();
  const [formValues, setFormValues] = useState({});
  const [formDetails, setFormDetails] = useState({});

  const [updateData, setUpdateData] = useState();

  const [selectedSubdistrict, setSelectedSubdistirct] = useState();
  const [selectedDestination, setSelectedDestination] = useState({});
  const [selectedCourier, setSelectedCourier] = useState();
  const [selectedService, setSelectedService] = useState();
  const [fullAddress, setFullAddress] = useState();
  const [productActive, setProducActive] = useState({});

  const [isShowingIframe, setIsShowingIframe] = useState(false);

  const user = auth?.currentUser;
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const { handleToast } = useContext(GlobalContext);
  const [payment, setPayment] = useState({
    bank: null,
    button: false,
    data: null,
  });

  console.log(state, "ini state");

  console.log(values, "ini values");

  const hasProduct = values?.cart?.carts?.some(
    (item) => item?.type === "product"
  );

  console.log(hasProduct);
  // const hasProduct = state?.product?.some((item) => item?.type === "product");

  const getName = () => {
    if (user?.displayName === null) {
      return removeSpecialCharacters(user?.email?.split("@")[0]);
    } else {
      return removeSpecialCharacters(user?.displayName);
    }
  };

  const getDataProduct = async () => {
    const productId = values?.cart?.carts[0]?.id;

    // if (values?.cart?.carts && values.cart.carts.length > 0) {
    try {
      const res = await getSingleDocumentFirebase(
        "listings_product",
        productId
      );

      console.log(res, "ssss");
      setProducActive(res);

      const data = await getSingleDocumentFirebase("forms", res?.formId);
      setFormDetails(data);
      setFormFields(data?.form_fields);
    } catch (error) {
      // console.log(error);
    }
    // }
  };

  console.log(productActive, "ini prod active");
  console.log(formDetails, "ini prod active");
  console.log(formFields, "ini prod active");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const renderFormFields = (opportunityValue) => {
    if (formFields?.length > 0) {
      return formFields?.map((field, index) => {
        const { label, type, name, placeholder, isRequired, options, formId } =
          field;
        const inputPlaceholder = placeholder || "";
        const inputIsRequired = isRequired || false;
        const inputProps = {
          name,
          onChange: handleInputChange,
          value: formValues[name] || "",
        };

        const handleSubmit = async () => {
          let updateData = formValues;
          setUpdateData(updateData);
          // if (params.id === "5fVAM1fDapHj2EaUpmdt") {
          // 	if (!updateData?.name) {
          // 		return toast({
          // 			title: "Please fill out name",
          // 			description: "",
          // 			isClosable: true,
          // 			duration: 9000,
          // 			status: "error"
          // 		})
          // 	} else if (!updateData?.email) {
          // 		return toast({
          // 			title: "Please fill out email",
          // 			description: "",
          // 			isClosable: true,
          // 			duration: 9000,
          // 			status: "error"
          // 		})
          // 	} else if (!updateData?.phoneNumber) {
          // 		return toast({
          // 			title: "Please fill out phone number",
          // 			description: "",
          // 			isClosable: true,
          // 			duration: 9000,
          // 			status: "error"
          // 		})
          // 	} else if (!selectedDestination) {
          // 		return toast({
          // 			title: "Please select City/Region",
          // 			description: "",
          // 			isClosable: true,
          // 			duration: 9000,
          // 			status: "error"
          // 		})
          // 	} else if (!fullAddress) {
          // 		return toast({
          // 			title: "Please fill out Full Address",
          // 			description: "",
          // 			isClosable: true,
          // 			duration: 9000,
          // 			status: "error"
          // 		})
          // 	} else if (Object.keys(selectedCourier)?.length === 0) {
          // 		return toast({
          // 			title: "Please select Courier",
          // 			description: "",
          // 			isClosable: true,
          // 			duration: 9000,
          // 			status: "error"
          // 		})
          // 	} else {
          // 		// const resultJne = await requestOrderJne(updateData)
          // 		// console.log(resultJne, "resultJne")
          // 		// if (Object.keys(resultJne)?.length === 0) {
          // 		// 	console.log(resultJne, "resultJne")
          // 		// 	return toast({
          // 		// 		title: "Error requesting shipping order",
          // 		// 		description: "",
          // 		// 		isClosable: true,
          // 		// 		duration: 9000,
          // 		// 		status: "error"
          // 		// 	})
          // 		// }
          // 		updateData.selectedDestination = selectedDestination;
          // 		updateData.fullAddress = fullAddress;
          // 		updateData.selectedCourier = selectedCourier;
          // 		updateData.orderCredential = orderCredential;
          // 		updateData.withShipping = true;
          // 		updateData.courier = "JNE";
          // 	};
          // }

          if (hasProduct) {
            updateData.shippingDetails = {
              courier: selectedCourier,
              destination: JSON.parse(selectedSubdistrict)?.subdistrict_name,
              region: selectedDestination.province,
              address: fullAddress,
              courierService: selectedService?.service,
              price: selectedService?.cost[0]?.value,
            };
          }

          updateData.formId = formId;
          updateData.opportunity_value = opportunityValue
            ? opportunityValue
            : "0";
          // updateData.email = "info@deoapp.com"
          // updateData.phoneNumber = moment(new Date()).valueOf().toString()
          // updateData.name = "visitor"

          const formFields = updateData;
          console.log(formFields, "ini fields");

          try {
            setLoading(true);
            // console.log(formFields, "formFields")
            const resultSubmitLeads = await axios.post(
              "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/createLead",
              formFields
            );
            console.log(resultSubmitLeads?.data?.message, "resultSubmitLeads");

            // if (window.location.href?.includes("bisabikinbrand1") ||
            // 	params.id === "5fVAM1fDapHj2EaUpmdt") {
            // 	const ipaymuPayload = {
            // 		companyId: "Z55P0SgE1tztgKziGsmE",
            // 		projectId: "PQwxxgMvG8ivkxyP5sHs",
            // 		product: [
            // 			ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
            // 				"Bisa Bikin Brand 1" :
            // 				ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ?
            // 					"Indonesia Spicing The World Event" :
            // 					ticketDetails?.title
            // 		],
            // 		qty: [
            // 			"1"
            // 		],
            // 		price: [
            // 			ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
            // 				"555000" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ? "1387500" : String(120000 * 1.1 + parseInt(selectedCourier?.price))
            // 		],
            // 		amount: ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
            // 			"555000" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ? "1387500" : String(120000 * 1.1 + parseInt(selectedCourier?.price)),
            // 		returnUrl: "https://bisabikinbrand1.deoapp.site", //your thank you page url
            // 		cancelUrl: "https://your-website.com/cancel-page", // your cancel page url
            // 		notifyUrl: "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/ipaymuCallbackPayment",
            // 		referenceId: getRandomString(), // your reference id or transaction id
            // 		buyerName: updateData?.name, // optional
            // 		buyerPhone: updateData?.phoneNumber, // optional
            // 		buyerEmail: updateData?.email,// optional
            // 		// va: ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
            // 		// 	"1179005223944575" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ? "1179002120001001" : "1179005223944575",
            // 		// apiKey: ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
            // 		// 	"C8132D77-7243-4556-B4FA-6B61669FD406" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ? "8A34D5B7-F3AE-42DC-9FFC-7BC0BBD121E2" : "C8132D77-7243-4556-B4FA-6B61669FD406",
            // 		// target: "production"
            // 		va: "0000007806848932", //dev
            // 		apiKey: "SANDBOX60851B52-A194-4C13-9531-031E5BD3E5F2",//dev
            // 		target: "development"//dev
            // 	};

            // 	ipaymuPayload.lead_id = resultSubmitLeads?.data?.message?.leadId;

            // 	try {
            // 		// console.log(ipaymuPayload, "ipaymuPayload")
            // 		const resultIpaymu = await createIpaymuPayment(ipaymuPayload);
            // 		console.log(resultIpaymu, "resultIpaymu");

            // 		if (resultIpaymu?.data?.status) {
            // 			setIpaymuResponse(resultIpaymu?.data);
            // 			try {
            // 				let dataEmail = {}
            // 				if (ticketDetails?.id === "xlGuDzpmezYiTyaa7op5") dataEmail = {
            // 					platform_name: ticketDetails?.title?.length > 20 ? ticketDetails?.title?.slice(0, 16) + "..." : ticketDetails?.title,
            // 					sender_email: ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz" ?
            // 						"bisabikinbrand1@gmail.com" : ticketDetails?.id === "xlGuDzpmezYiTyaa7op5" ?
            // 							"indonesiaspicingtheworld5@gmail.com" : "",
            // 					recipient_email: updateData?.email,
            // 					recipient_name: updateData?.name,
            // 					cc: ['reinhart@importir.co'],
            // 					subject: updateData.subject || "Pendaftaran " + ticketDetails?.title,
            // 					title: "INVOICE",
            // 					message: `<p>Hallo ${updateData?.name}</p><p> / ${updateData?.email}</p>
            // 					<p> Anda telah melakukan pendaftaran ${ticketDetails?.title},</p><p>
            // 					berikut informasi Anda: ${ticketDetails?.title}</p><p><br></p><p>
            // 					Data Anda Nama: ${updateData?.name}</p><p> Phone: ${updateData?.phoneNumber}</p>
            // 					<p> Email: ${updateData?.email}</p>

            // 					<p>Harga : Rp.${updateData?.opportunity_value}</p>
            // 					<p>PPN (11%): Rp.${(parseInt(updateData?.opportunity_value) * 0.11)?.toFixed()}</p>

            // 					<p><strong> Total Bayar: Rp.${(parseInt(updateData?.opportunity_value) * 1.11)?.toFixed()}</strong></p>

            // 					<p> Silahkan melakukan pembayaran ke link berikut klik disini Pastikan data yang kamu diisi sudah BENAR</p>
            // 					<p><br></p><p><br></p><p><br></p><p>Silahkan melakukan pembayaran pada link berikut di bawah ini:</p>
            // 					<p><br></p><p>${resultIpaymu?.data?.data?.Data?.Url}</p>
            // 					<br>
            // 					<p>*Link pembayaran akan expire dalam 24 jam, jika tidak dilakukan pembayaran maka anda harus
            // 					mendaftar kembali.</p>
            // 					<p>CP : Muhammad Kasim https://wa.me/6281282518873</p>

            // 					`
            // 				}
            // 				if (ticketDetails?.id === "FHFdfaoIpGKOYaOSnBwz") dataEmail = {
            // 					platform_name: ticketDetails?.title?.length > 20 ? ticketDetails?.title?.slice(0, 16) + "..." : ticketDetails?.title,
            // 					sender_email: "bisabikinbrand1@gmail.com",
            // 					recipient_email: updateData?.email,
            // 					recipient_name: updateData?.name,
            // 					cc: ['reinhart@importir.co'],
            // 					subject: updateData.subject || "Pembelian " + ticketDetails?.title,
            // 					title: "INVOICE",
            // 					message: `<p>Hallo ${updateData?.name}</p><p> / ${updateData?.email}</p>
            // 					<p> Anda telah melakukan pemesanan ${ticketDetails?.title},</p><p>
            // 					berikut informasi Anda: ${ticketDetails?.title}</p><p><br></p><p>
            // 					Data Anda Nama: ${updateData?.name}</p><p> Phone: ${updateData?.phoneNumber}</p>
            // 					<p> Email: ${updateData?.email}</p>

            // 					<p>Harga : Rp.${updateData?.opportunity_value}</p>
            // 					<p>PPN (11%): Rp.${(parseInt(updateData?.opportunity_value) * 0.11)?.toFixed()}</p>

            // 					<p><strong> Total Bayar: Rp.${(parseInt(updateData?.opportunity_value) * 1.11)?.toFixed()}</strong></p>

            // 					<p> Silahkan melakukan pembayaran ke link berikut klik disini Pastikan data yang kamu diisi sudah BENAR</p>
            // 					<p><br></p><p><br></p><p><br></p><p>Silahkan melakukan pembayaran pada link berikut di bawah ini:</p>
            // 					<p><br></p><p>${resultIpaymu?.data?.data?.Data?.Url}</p>
            // 					<br>
            // 					<p>*Link pembayaran akan expire dalam 24 jam, jika tidak dilakukan pembayaran maka anda harus
            // 					mendaftar kembali.</p>
            // 					<p>PEMBELIAN BUKU DENGAN PENGIRIMAN AKAN DIKENAKAN BIAYA PENGIRIMAN DI LUAR BIAYA BUKU. Harap menunggu email konfirmasi lebih lanjut untuk form alamat pengiriman.</p>
            // 					<p>CP : Muhammad Kasim https://wa.me/6281282518873</p>
            // 					`
            // 				};

            // 				if (ticketDetails?.id === "5fVAM1fDapHj2EaUpmdt") {
            // 					dataEmail = {
            // 						platform_name: ticketDetails?.title?.length > 20 ? ticketDetails?.title?.slice(0, 16) + "..." : ticketDetails?.title,
            // 						sender_email: "bisabikinbrand1@gmail.com",
            // 						recipient_email: updateData?.email,
            // 						recipient_name: updateData?.name,
            // 						cc: ['reinhart@importir.co'],
            // 						subject: updateData.subject || "Pembelian " + ticketDetails?.title,
            // 						title: "INVOICE",
            // 						message: `<p>Hallo ${updateData?.name}</p><p> / ${updateData?.email}</p>
            // 						<p> Anda telah melakukan pemesanan ${ticketDetails?.title},</p><p>
            // 						berikut informasi Anda: ${ticketDetails?.title}</p><p><br></p><p>
            // 						Data Anda Nama: ${updateData?.name}</p><p> Phone: ${updateData?.phoneNumber}</p>
            // 						<p> Email: ${updateData?.email}</p>

            // 						<p>Harga : Rp.${updateData?.opportunity_value}</p>
            // 						<p>PPN (11%): Rp.${(parseInt(updateData?.opportunity_value) * 0.11)?.toFixed()}</p>

            // 						<p><strong> Total Bayar: Rp.${(parseInt(updateData?.opportunity_value) * 1.11)?.toFixed()}</strong></p>

            // 						<p> Silahkan melakukan pembayaran ke link berikut klik disini Pastikan data yang kamu diisi sudah BENAR</p>
            // 						<p><br></p><p><br></p><p><br></p><p>Silahkan melakukan pembayaran pada link berikut di bawah ini:</p>
            // 						<p><br></p><p>${resultIpaymu?.data?.data?.Data?.Url}</p>
            // 						<br>
            // 						<p>*Link pembayaran akan expire dalam 24 jam, jika tidak dilakukan pembayaran maka anda harus
            // 						mendaftar kembali.</p>
            // 						<p>PEMBELIAN BUKU DENGAN PENGIRIMAN AKAN DIKENAKAN BIAYA PENGIRIMAN DI LUAR BIAYA BUKU. Harap menunggu email konfirmasi lebih lanjut untuk form alamat pengiriman.</p>
            // 						<p>CP : Muhammad Kasim https://wa.me/6281282518873</p>
            // 						`
            // 					};
            // 					try {
            // 						await setDocumentFirebase('orders', ipaymuPayload?.referenceId, { ...formFields, product: ticketDetails?.title, is_production: false }, "PQwxxgMvG8ivkxyP5sHs");
            // 						console.log("creating order document with ID: ", ipaymuPayload?.referenceId)
            // 					} catch (error) {
            // 						console.log("error creating order document ::::::", error)
            // 					}
            // 				}

            // 				const res = await axios.post("https://new-third-party.importir.com/api/email/send-message", dataEmail)
            // 				// console.log(dataEmail, "dataEmail")
            // 				// console.log({ res })

            // 			} catch (error) {
            // 				console.log(error.message, "error sending email")
            // 			}

            // 			toast({
            // 				title: 'Success',
            // 				description: 'Registration success',
            // 				status: 'success',
            // 				isClosable: true,
            // 				duration: 9000
            // 			});
            // 			window.open(resultIpaymu?.data?.data?.Data?.Url);
            // 		} else {
            // 			console.log("resultIpaymu", resultIpaymu);
            // 			toast({
            // 				title: 'Error',
            // 				// description: resultIpaymu?.data?.message,
            // 				description: 'something wrong happened',
            // 				status: 'error',
            // 				isClosable: true,
            // 				duration: 9000
            // 			})
            // 		}
            // 	} catch (error) {
            // 		// console.log(error);
            // 		toast({
            // 			title: 'error',
            // 			description: error.message,
            // 			isClosable: true,
            // 			duration: 9000,
            // 			status: 'error'
            // 		})
            // 	} finally {
            // 		setLoading(false)
            // 	}
            // }

            console.log("formDetails", formDetails.paymentMethod === "xendit");

            if (formDetails.paymentMethod === "xendit") {
              setIsShowingIframe(true);

              if (hasProduct) {
                window.open(
                  `https://crm.deoapp.com/payment/product/xendit/${
                    values.webConfig.projectsId
                  }/${updateData?.phoneNumber}/${
                    updateData?.name
                  }/${encodeURIComponent(updateData.formId)}`
                );
              } else {
                window.open(
                  `https://crm.deoapp.com/payment/ticket/xendit/${
                    values.webConfig.projectsId
                  }/${updateData?.phoneNumber}/${
                    updateData?.name
                  }/${encodeURIComponent(updateData.formId)}`
                );
              }
            }
          } catch (error) {
            console.log(error, "ini error");
          } finally {
            setLoading(false);
            // iframe?.contentWindow?.focus();
          }
          // Implement your form submission logic here
        };

        console.log(formValues, "ini form value");

        return (
          <FormControl key={index} isRequired={inputIsRequired}>
            {type !== "button" && (
              <Text>
                {label}
                {inputIsRequired ? (
                  <span style={{ color: "red" }}>*</span>
                ) : null}
              </Text>
            )}
            {type === "text" && (
              <Input
                my={3}
                type="text"
                placeholder={inputPlaceholder}
                {...inputProps}
              />
            )}
            {type === "number" && (
              <Input
                my={3}
                type="number"
                placeholder={inputPlaceholder}
                {...inputProps}
              />
            )}
            {type === "email" && (
              <Input
                my={3}
                type="email"
                placeholder={inputPlaceholder}
                {...inputProps}
              />
            )}
            {type === "textarea" && (
              <Textarea
                name={name}
                placeholder={inputPlaceholder}
                {...inputProps}
              />
            )}
            {type === "select" && (
              <Select
                name={name}
                placeholder={inputPlaceholder}
                {...inputProps}
              >
                {options?.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            )}
            {type === "date" && (
              <Input
                my={3}
                type="date"
                placeholder={inputPlaceholder}
                {...inputProps}
              />
            )}
            {type === "time" && (
              <Input
                my={3}
                type="time"
                placeholder={inputPlaceholder}
                {...inputProps}
              />
            )}
            {type === "file" && (
              <Input
                my={3}
                type="file"
                placeholder={inputPlaceholder}
                {...inputProps}
              />
            )}

            {type === "request" && (
              <ShippingRajaOngkir
                selectedSubdistrict={selectedSubdistrict}
                setSelectedSubdistirct={setSelectedSubdistirct}
                selectedDestination={selectedDestination}
                setSelectedDestination={setSelectedDestination}
                selectedCourier={selectedCourier}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                setSelectedCourier={setSelectedCourier}
                setFullAddress={setFullAddress}
                weight={parseInt(productActive?.weight) || 1000}
              />
            )}

            {type === "button" && (
              <Button
                my={5}
                w="100%"
                isLoading={loading}
                isDisabled={loading}
                loadingText="Submitting data..."
                onClick={handleSubmit}
                colorScheme="blue"
              >
                {label}
              </Button>
            )}
          </FormControl>
        );
      });
    }
  };

  // console.log({
  //   courier: selectedCourier,
  //   destination: JSON?.parse(selectedSubdistrict)?.subdistrict_name,
  //   region: JSON?.parse(selectedSubdistrict)?.province,
  //   address: fullAddress,
  //   courierService: selectedService?.service,
  //   price: selectedService,
  // });

  const handleOrderPayConfirm = async () => {
    setLoading(true);

    const newObj = { ...payment };
    delete newObj.button;

    if (!hasProduct) {
      const updatedOrder = {
        ...newObj,
        orders: [...values?.cart?.carts],
        paymentStatus: "PENDING",
        orderStatus: "onProcess",
        paymentMethod: "virtualAccount",
        module: "lms",
        tax: 0,
        discount: 0,
        createdAt: new Date(),
        is_production: true,
        projectId: values?.webConfig?.projectsId,
      };

      const fixPrice = parseInt(state.total);
      const orderId = getRandomString();

      return handlePaymentTransfer(orderId, updatedOrder, fixPrice);
    } else {
      const updatedOrder = {
        ...newObj,
        orders: [...values?.cart?.carts],
        paymentStatus: "PENDING",
        orderStatus: "onProcess",
        paymentMethod: "virtualAccount",
        module: "lms",
        tax: 0,
        discount: 0,
        createdAt: new Date(),
        is_production: true,
        projectId: values?.webConfig?.projectsId,
        shippingDetails: {
          courier: selectedCourier,
          destination: selectedSubdistrict,
          fullAddress: fullAddress,
          service: selectedService,
        },
      };

      const fixPrice = parseInt(state.total) + selectedService?.cost[0]?.value;
      const orderId = getRandomString();

      return handlePaymentTransfer(orderId, updatedOrder, fixPrice);
    }
  };

  const handlePaymentTransfer = async (id, updatedOrder, fixPrice) => {
    const baseUrl =
      "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/";

    const orderName = getRandomString();
    setOrderId(orderName);

    ////PLEASE NOTE: THIS IS FOR XENDIT
    ////DATA STRUCTURE IS THE FOLLOWING:
    const data = {
      xenditId: values?.webConfig?.xenditId,
      // xenditId: "64a42ff2e3b3085d0ad8da92",// dev
      orderId: id,
      // amount: state.total,
      amount: parseInt(values?.cart?.carts[0]?.price),
      bankCode: payment?.bank,
      name: getName(),
      companyId: values?.webConfig?.companiesId,
      outletId: "ONLINE",
      projectId: values?.webConfig?.projectsId,
      module: "lms",
      feeRule: true,
      userId: auth?.currentUser?.uid,
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_PAYMENT_KEY,
      },
    };

    // return console.log(data);
    // setLoading(false);

    try {
      const res = await axios.post(`${baseUrl}paymentCreateVA`, data, options);
      console.log("result create payment VA: ", res);
      try {
        setOrderId(res?.data?.data?.external_id);
        const addOrders = await setDocumentFirebase(
          "orders",
          res?.data?.data?.external_id,
          { userId: auth?.currentUser?.uid, ...updatedOrder },
          values?.webConfig?.projectsId
        );

        console.log(addOrders, "ini ordernya");
      } catch (error) {
        console.log("error setdoc orders", error.message);
      }

      if (res?.data?.status) {
        setPayment({ ...payment, ...res.data, button: true });
        onOpen();
      } else {
        handleToast("Oops, an error happened!", res?.data?.message, "error");
      }
    } catch (error) {
      console.log(error, "ini error");
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    setValue(payment?.data?.account_number);
    onCopy();
  };

  useEffect(() => {
    getDataProduct();

    return () => {};
  }, []);

  console.log(values);

  return (
    <>
      <Box textAlign="center" p="10">
        <Heading size="sm">Checkout</Heading>
        <Text>Check your order list again</Text>
      </Box>

      {values?.cart?.carts.map((item, id) => (
        // {state?.product?.map((item, id) => (
        <>
          <CheckoutDrawerComponent key={id} {...item} />

          <Divider my={3} />
        </>
      ))}

      {hasProduct ? (
        <Stack my={3}>
          <Text fontWeight={500} align={"center"}>
            Isi Alamat Untuk Pengiriman
          </Text>
          {/* <Box my={3}>
            <Text fontWeight={500} align={"center"}>
              Isi Alamat Untuk Pengiriman
            </Text>
            <ShippingRajaOngkir
              selectedSubdistrict={selectedSubdistrict}
              setSelectedSubdistirct={setSelectedSubdistirct}
              selectedDestination={selectedDestination}
              setSelectedDestination={setSelectedDestination}
              selectedCourier={selectedCourier}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              setSelectedCourier={setSelectedCourier}
              setFullAddress={setFullAddress}
              weight={parseInt(productActive?.weight) || 1000}
            />
          </Box> */}

          {renderFormFields(parseInt(state?.total))}

          <Divider my={3} />
        </Stack>
      ) : null}

      <Flex gap={2} align="center" justify="right" my="2">
        {!hasProduct ? (
          <Box>
            <Heading size="sm">Total: </Heading>
            {/* <PriceTag price={state?.total} currency={"IDR"} /> */}
            <PriceTag
              price={parseInt(values.cart?.carts[0]?.price)}
              currency={"IDR"}
            />
          </Box>
        ) : (
          <Box>
            <Heading size="sm">Total: </Heading>
            {selectedService ? (
              <PriceTag
                price={
                  parseInt(values.cart.carts[0]?.price) +
                  (selectedService?.cost[0]?.value || 0)
                }
                currency={"IDR"}
              />
            ) : (
              <PriceTag
                price={parseInt(values.cart.carts[0]?.price)}
                currency={"IDR"}
              />
            )}
            <Text fontSize={10} fontStyle={"italic"}>
              Include Shipping
            </Text>
          </Box>
        )}
      </Flex>
      <Divider my="5" />

      {hasProduct ? (
        <></>
      ) : (
        <Box p="4" border="1px solid #f4f4f4" m="2" borderRadius="2">
          <Heading size="sm" my={10} textAlign="center">
            Choose your payment method
          </Heading>

          <RadioGroup defaultValue="2" isDisabled={payment.button}>
            <Stack spacing={5} direction="column">
              {banks?.map((bank, i) => (
                <Radio
                  key={i}
                  colorScheme={values?.webConfig?.colorScheme}
                  value={bank.value}
                  onChange={(e) => {
                    setPayment({
                      ...payment,
                      bank: e.target.value,
                      data: bank,
                    });
                  }}
                >
                  <Flex flexDir="row" alignItems="center" gap={2}>
                    <Image maxW="70" src={bank?.uri} resize={"block"} />
                    <Text fontSize={13} color="gray">
                      {bank?.name}
                    </Text>
                  </Flex>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
          <Divider mb="3" />

          {payment?.bank && payment?.button === false ? (
            <Button
              colorScheme={values?.webConfig?.colorScheme}
              w="full"
              onClick={handleOrderPayConfirm}
              isLoading={loading}
              loadingText="Processing..."
              isDisabled={loading}
            >
              Proceed Payment
            </Button>
          ) : (
            <></>
          )}
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{payment?.bank} Virtual Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {values?.cart?.carts?.map((item, i) => (
                // {state.product.map((item, i) => (
                <Box>
                  <Stack
                    spacing={2}
                    rounded={5}
                    borderWidth="1px"
                    p={3}
                    bgColor="white"
                    shadow={"md"}
                    align={"left"}
                    justify={"space-between"}
                    cursor={"pointer"}
                    _hover={{
                      bg: "gray.100",
                      transform: "scale(1.02)",
                      transition: "0.3s",
                      cursor: "pointer",
                    }}
                  >
                    <Box p={2} align={"center"}>
                      <Image w={200} src={item?.image} />
                    </Box>
                    <Flex justify={"space-between"} align={"center"}>
                      <Heading size={"sm"} textTransform="capitalize">
                        {item?.name}
                      </Heading>
                      <Button
                        variant={"unstyled"}
                        onClick={() => console.log("delete", item)}
                      ></Button>
                    </Flex>

                    <Stack>
                      <Heading size={"md"} color="green.500">
                        Rp. {formatFrice(parseInt(item?.price) * item?.qty)}
                      </Heading>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </Box>
            <Box p={5}>
              <Grid templateColumns="30% 70%" gap="4" p="4">
                <Box>
                  <Text>VA {payment?.bank}: </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">{payment?.data?.account_number}</Text>
                  <Button
                    colorScheme="green"
                    size="sm"
                    onClick={handleCopy}
                    variant="outline"
                  >
                    {hasCopied ? (
                      <>
                        {" "}
                        <FiCopy mx={2} /> Copied!
                      </>
                    ) : (
                      <>
                        Press to copy <FiCopy mx={2} />
                      </>
                    )}
                  </Button>
                </Box>
                <Box>
                  <Text>Name: </Text>
                </Box>
                <Box>
                  <Text>{payment?.data?.name}</Text>
                </Box>
                <Box>
                  <Text>Order Id:</Text>
                </Box>
                <Box>
                  <Text>{orderId}</Text>
                </Box>
              </Grid>
              <VStack mt={20}>
                <Heading size="md">Please pay before:</Heading>
                <Text>
                  {moment(payment?.expiration_date).format(
                    "dddd MMMM Do YYYY, h:mm:ss a"
                  )}
                </Text>
              </VStack>

              {/* <Button size='sm' variant='outline' colorScheme='green' onClick={onOpen}>Check Payment Status</Button> */}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onClose();
                setPayment({ ...payment, button: false });
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MicroCheckoutPage;
