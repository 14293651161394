import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addArrayFirebase, loginUser } from '../../Api/firebaseApi'
import { auth } from '../../Config/firebase'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'
import { browserSessionPersistence, setPersistence } from 'firebase/auth';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function LoginPage() {
  const [data, setData] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { webConfig } = useContext(GlobalContext);
  const navigate = useNavigate();
  const toast = useToast();
  
  // Get the pending location from localStorage (if any)
  const pendingLocation = localStorage.getItem('pendingLocation');

  // Function to show toast notifications
  const showToast = (title, description, status) => {
    toast({
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
  };

  const handleLogin = async () => {
    // Form validation
    if (!data.email || !data.password) {
      showToast('Error', 'Please enter both email and password', 'error');
      return;
    }

    setLoading(true);
    try {
      // Set persistence based on "Remember Me" checkbox
      if (rememberMe) {
        await setPersistence(auth, browserSessionPersistence);
      }
      
      const result = await loginUser(data.email, data.password);
      
      if (result.status === 'success') {
        showToast('Login Success', 'You have successfully logged in', 'success');
        
        // Add user to the projects array if needed
        if (auth.currentUser && webConfig?.projectsId) {
          await addArrayFirebase('users', auth.currentUser.uid, 'users', webConfig.projectsId);
        }
        
        // Handle redirection
        if (!pendingLocation) {
          navigate('/');
        } else {
          try {
            // Parse and navigate to the pending location
            const redirectUrl = JSON.parse(pendingLocation);
            window.location.href = redirectUrl;
          } catch (error) {
            // If parsing fails, just go to home
            navigate('/');
          }
        }
      } else {
        showToast(result.status || 'Error', result.data || 'Login failed', 'error');
      }
    } catch (error) {
      console.error('Login error:', error);
      
      // Provide user-friendly error messages
      const errorMessage = 
        error.code === 'auth/user-not-found' ? 'User not found. Please check your email.' :
        error.code === 'auth/wrong-password' ? 'Incorrect password. Please try again.' :
        error.code === 'auth/invalid-email' ? 'Invalid email format.' :
        error.code === 'auth/too-many-requests' ? 'Too many failed login attempts. Please try again later.' :
        'An error occurred during login. Please try again.';
      
      showToast('Login Failed', errorMessage, 'error');
    } finally {
      setLoading(false);
      localStorage.removeItem("pendingLocation");
    }
  };

  // Check if user is already logged in
  const checkAuthStatus = () => {
    if (auth.currentUser) {
      navigate('/');
    }
  };

  // Handle "Enter" key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    checkAuthStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      maxW="lg"
      py={{ base: '8', md: '12' }}
      px={{ base: '4', sm: '8' }}
    >
      <VStack w="full" spacing="8">
        <Stack spacing="6" w="full" align="center">
          <Heading
            size={{ base: 'lg', md: 'xl' }}
            fontWeight="bold"
            color={`${webConfig?.colorScheme}.600`}
          >
            Welcome Back
          </Heading>
          <Text fontSize="md" color="gray.600">
            Log in to your account to continue
          </Text>
        </Stack>
        
        <Box
          w="full"
          py={{ base: '6', sm: '8' }}
          px={{ base: '6', sm: '10' }}
          bg="white"
          boxShadow="lg"
          borderRadius="xl"
        >
          <Stack spacing="6">
            <FormControl isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input 
                id="email" 
                type="email" 
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                placeholder="your-email@example.com"
                bg="gray.50"
                borderColor="gray.300"
                _hover={{ borderColor: `${webConfig?.colorScheme}.400` }}
                _focus={{ borderColor: `${webConfig?.colorScheme}.500`, boxShadow: `0 0 0 1px ${webConfig?.colorScheme}.500` }}
              />
            </FormControl>
            
            <FormControl isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={data.password}
                  onChange={(e) => setData({ ...data, password: e.target.value })}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter your password"
                  bg="gray.50"
                  borderColor="gray.300"
                  _hover={{ borderColor: `${webConfig?.colorScheme}.400` }}
                  _focus={{ borderColor: `${webConfig?.colorScheme}.500`, boxShadow: `0 0 0 1px ${webConfig?.colorScheme}.500` }}
                />
                <InputRightElement
                  cursor="pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Icon
                    as={showPassword ? AiOutlineEye : AiOutlineEyeInvisible}
                    color="gray.500"
                    fontSize="lg"
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            
            <HStack justify="space-between" w="full">
              <Checkbox 
                colorScheme={webConfig?.colorScheme}
                isChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              >
                Remember me
              </Checkbox>
              <Button 
                variant="link" 
                colorScheme={webConfig?.colorScheme} 
                size="sm" 
                onClick={() => navigate('/authentication/forgot-password')}
              >
                Forgot password?
              </Button>
            </HStack>
            
            <Button
              variant="solid"
              colorScheme={webConfig?.colorScheme}
              size="lg"
              isLoading={loading}
              loadingText="Signing in"
              onClick={handleLogin}
              w="full"
              py="6"
            >
              Sign in
            </Button>
            
            <HStack spacing="1" justify="center">
              <Text color="gray.600">Don't have an account?</Text>
              <Button 
                variant="link" 
                colorScheme={webConfig?.colorScheme} 
                onClick={() => navigate('/authentication/signup')}
              >
                Sign up
              </Button>
            </HStack>
          </Stack>
        </Box>
      </VStack>
    </Container>
  );
}

export default LoginPage;