import { useToast } from "@chakra-ui/react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  getCollectionFirebase,
  getCollectionFirebaseV2,
  getCollectionWhereFirebase,
  getSingleDocumentFirebase,
} from "../../Api/firebaseApi";
import { auth, db } from "../../Config/firebase";
import { GlobalContext } from "./GlobalContext";

function GlobalProvider({ children }) {
  const [data, setData] = useState({});
  const [cart, setCart] = useState({});
  const [order, setOrder] = useState([]);
  // const [wishlist, setWishlist] = useState({})
  const [authentication, setAuthentication] = useState();
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [courses, setCourses] = useState([]);
  const toast = useToast();

  const handleToast = (title, description, status) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  };

  const getWebConfig = async () => {
    // let arr =[];

		const subdomain = window.location.host.split('.')
		if (subdomain.length > 0 && subdomain[0] !== 'deoapp') {
			if (subdomain[1] === "localhost:3000") {
				subdomain[1] = "deoapp.site"
			}

			const res = await getCollectionWhereFirebase('domains', 'domain', 'array-contains', `${subdomain[0]}.${subdomain[1]}`)
			// const res = await getCollectionWhereFirebase('domains', 'domain', 'array-contains', `bisabikinbrand1.com`)

      console.log(res[0]?.id, 'ini id')
			// PLEASE NOTE : document ID of domains collection is project ID where it belongs to
			const resPages = await getSingleDocumentFirebase('pages', res[0]?.id)

      console.log(resPages, 'in ires')

			const companyData = await getSingleDocumentFirebase('companies', resPages?.companiesId)


      if (resPages) {
        console.log({
          webConfig: {
            ...resPages,
            ...companyData,
            companiesId: companyData?.id,
          },
        });
        setData({
          webConfig: {
            ...resPages,
            ...companyData,
            companiesId: companyData?.id,
          },
        });
        const conditionsN = [
          { field: "projectsId", operator: "==", value: resPages.projectsId },
        ];
        try {
          // const resultNews = await getCollectionFirebase(
          // 	'news',
          // 	{ conditionsN },
          // 	{ sortBy:null },
          // 	// { sortBy: { field: "createdAt", direction: "desc" } },
          // 	{ limitValue: 3 },
          // 	{ startAfterData: null }
          // );
          const sortBy = { field: "createdAt", direction: "desc" };
          const resultNews = await getCollectionFirebaseV2(
            "news",
            conditionsN,
            sortBy,
            // { sortBy: { field: "createdAt", direction: "desc" } },
            3,
            null
          );

          if (resultNews?.length > 0) setNews(resultNews);
        } catch (error) {
          console.log("error getting news..", error.message);
        }

        const conditions = [
          { field: "projectsId", operator: "==", value: resPages.projectsId },
        ];

        const sortBy = { field: "title", direction: "asc" };
        // const sortBy = { field: 'createdAt', direction: 'desc'};
        const limitValue = 10;

        const res = await getCollectionFirebase(
          "courses",
          { conditions },
          { sortBy },
          { limitValue },
          { startAfterData: null }
        );

        // const sortRes = res.sort((a, b) => {return (a?.createdAt?.seconds - b?.createdAt?.seconds)})
        // const sortRes = res.sort((a,b) => (a?.title?.localeCompare(b.title)))
        const sortRes = res?.sort((a, b) => {
          const sortNumber = /(\d+)/;
          const matchResult = a.title.match(sortNumber);
          const numA = matchResult ? parseInt(matchResult[0]) : 0;
          const matchResults = b.title.match(sortNumber);
          const numB = matchResults ? parseInt(matchResults[0]) : 0;

          return numA - numB;
        });
        setCourses(sortRes);
        // setCourses(resultCourses)
        setLoading(false);
      } else {
        setData({ webConfig: null });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getWebConfig();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const authSub = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthentication({ auth: true, uid: user?.uid });
      } else {
        setAuthentication({ auth: false });
      }
    });
    return () => {
      authSub();
    };
  }, [data?.webConfig]);

  useEffect(() => {
    const cartSnapshot = onSnapshot(
      doc(
        db,
        `carts`,
        `${data?.webConfig?.projectsId}-${auth?.currentUser?.uid}`
      ),
      (doc) => {
        setCart(doc.data());
      }
    );

    return () => {
      cartSnapshot();
      // wishListSnapshot()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication?.auth, data?.webConfig]);

  return (
    <GlobalContext.Provider
      value={{
        ...data,
        ...authentication,
        setLoading,
        loading,
        handleToast,
        cart,
        order,
        setOrder,
        // wishlist,
        news,
        courses,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
