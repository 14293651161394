import { Box, Button, Center, Flex, Heading, Icon, Input, InputGroup, InputLeftAddon, InputLeftElement, InputRightAddon, InputRightElement, List, ListItem, Spinner, Stack, Text, Textarea } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { formatFrice } from '../../Utils/NumberUtils';
import { getRandomString } from '../../Utils/RandomString';
import { FcCheckmark } from 'react-icons/fc';


const JneForm = (
    { selectedDestination,
        setSelectedDestination,
        selectedCourier,
        setSelectedCourier,
        setFullAddress,
    }
) => {
    // const [originResults, setOriginResults] = useState([]);
    const [destinationResults, setDestinationResults] = useState([]);
    const [destinationSearch, setDestinationSearch] = useState("")
    const [destinationSuggestions, setDestinationSuggestions] = useState([]);
    const [fetchingPrice, setFetchingPrice] = useState(false);
    const [priceSuggestions, setPriceSuggestions] = useState({});
    const [fetchingDestinations, setFetchingDestinations] = useState(false);


    const getDestination = async () => {
        setFetchingDestinations(true);
        const url = "https://getdestination-qwuyxef5gq-uc.a.run.app"
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            data: {
                username: "DBRAND",
                api_key: "e34a6683064f208340e6b8e683c7163f",
                is_production: true
            }
        };
        try {
            const result = await axios(config);
            // console.log("result destination", result)
            if (result?.data?.data !== undefined && result?.data?.data?.detail?.length > 0) {
                setDestinationResults(result?.data?.data?.detail);
            }
        } catch (error) {
            console.log(error, "error getting destination")
        } finally {
            setFetchingDestinations(false);
        };
    };

    const handleDestinationSearch = value => {
        setDestinationSearch(value);
    };


    const getPricing = async () => {
        setFetchingPrice(true)
        const url = "https://getpricing-qwuyxef5gq-uc.a.run.app"
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            withCredentials: false,
            url: url,
            data: {
                username: "DBRAND",
                api_key: "e34a6683064f208340e6b8e683c7163f",
                is_production: true,
                from: "CGK10000",
                thru: selectedDestination?.City_Code,
                weight: 1
            },
        };
        try {
            const result = await axios(config);
            if (result?.data?.data !== undefined && result?.data?.data?.length > 0) {
                setPriceSuggestions(result)
            }
        } catch (error) {
            console.log(error.response.data, "error getting price")
        } finally {
            setFetchingPrice(false);
        };
    };




    useEffect(() => {
        getDestination();

        return () => {

        }
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            const found = destinationResults.filter((elem) => {
                return JSON.stringify(elem).toLowerCase().indexOf(destinationSearch.toLowerCase()) !== -1
            });

            if (destinationSearch?.length !== 0) {
                setDestinationSuggestions(found);
            }
        }, 800);
        return () => {
            clearTimeout(timeout);
        }
    }, [destinationSearch]);


    useEffect(() => {
        if (Object.keys(selectedDestination).length !== 0) getPricing();
        return () => {

        }
    }, [selectedDestination]);


    return (
        <>
            <Center my={3}>
                <Box w='md'>
                    <Text>Dikirim dari :</Text>
                    <Text fontWeight='bold'>Jakarta Selatan</Text>

                    <Stack gap={3}>
                        <Text fontWeight='bold' mt={10}> Silakan Isi Alamatmu:</Text>
                        <InputGroup>
                            <Input
                                value={Object.keys(selectedDestination).length > 0 ?
                                    selectedDestination?.City_Name :
                                    destinationSearch}
                                placeholder={fetchingDestinations ? "Loading..." : "Kota / Kabupaten Tujuan"}
                                onChange={e => handleDestinationSearch(e.target.value)}
                                isDisabled={fetchingDestinations}
                            />
                            <InputRightElement
                                _hover={{
                                    bg: 'gray.200'
                                }}
                                cursor='pointer'
                                onClick={() => {
                                    setSelectedDestination({});
                                    setDestinationSearch("");
                                    setDestinationSuggestions([]);
                                }}
                            >
                                <Icon as={AiOutlineClose} />
                            </InputRightElement>
                        </InputGroup>
                        {destinationSuggestions.length > 0 && Object.entries(selectedDestination).length === 0 && (
                            <List bg="gray.50" mt={-3}>
                                {destinationSuggestions.map((suggestion, index) => (
                                    <ListItem
                                        onClick={() => {
                                            // console.log(suggestion)
                                            setSelectedDestination(suggestion)
                                        }}
                                        borderBottomWidth={1}
                                        p={2}
                                        cursor="pointer"
                                        _hover={{
                                            backgroundColor: "gray.200"
                                        }}
                                        key={index}
                                    >
                                        <strong>{suggestion?.City_Name}</strong>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                        {/* {destinationSuggestions?.length === 0 && destinationSearch?.length !== 0 && 
                            <Text color="gray.800">
                                <i>Kota / kecamatan tidak ditemukan.</i>
                            </Text>
                        } */}

                    </Stack>
                    <Stack>
                        <Text>Alamat Lengkap:</Text>
                        <Textarea placeholder="Jl. Wana Kencana blok J1/10, Tangerang Selatan" onChange={e => setFullAddress(e.target.value)} />
                    </Stack>
                    
                    {fetchingPrice ?
                        <Center my={5}>
                            <Spinner color="gray.500" />
                        </Center>
                        : priceSuggestions?.data?.data?.length > 0 ?
                            priceSuggestions?.data?.data?.sort((a, b) => parseInt(a.price) - parseInt(b.price)).map((x, i) => (
                                <Flex
                                    cursor="pointer"
                                    _hover={{
                                        bg: "gray.200"
                                    }}
                                    _active={{
                                        bg: "gray.300"
                                    }}
                                    bg={selectedCourier?.service_code === x?.service_code ? "gray.100" : "white"}
                                    justifyContent='space-between'
                                    borderBottomWidth={1}
                                    gap={2}
                                    key={i}
                                    p={1}
                                    onClick={() => {
                                        // requestOrderJne(x)
                                        setSelectedCourier(x)
                                    }}
                                >
                                    <Stack>
                                        <Text><strong>{x?.service_display}</strong> <i style={{ color: "gray", fontSize: 12 }}>({x?.goods_type})</i></Text>
                                        <Text fontSize={12} color='gray.600'>
                                            Estimasi {x?.etd_from !== x?.etd_thru ? x?.etd_from + "-" + x?.etd_thru : x?.etd_from}
                                            {x?.times === "D" && " hari"}
                                        </Text>
                                    </Stack>
                                    <Stack alignItems="flex-end">
                                        <Text fontWeight={'bold'}>
                                            {x?.currency} {formatFrice(parseInt(x?.price))}
                                        </Text>
                                        {selectedCourier?.service_code === x?.service_code ? <Icon as={FcCheckmark} /> : <></>}

                                    </Stack>
                                </Flex>
                            )) : <></>
                    }
                </Box>
            </Center>
        </>
    )
}

export default JneForm