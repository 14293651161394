import {
     AccordionPanel, AccordionIcon, Accordion,
     AccordionItem,
     AccordionButton, Button, Image, Input, InputGroup, InputLeftElement, InputRightElement, useColorModeValue, Divider, Spacer, Box, Heading, SimpleGrid, HStack, Flex, VStack, Text, Icon,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdLocationOn } from "react-icons/md";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';
import { useContext } from 'react';
import { PriceTag } from '../../Components/Carts/CartDrawer/PriceTag';
import moment from 'moment';
import { FiCalendar, FiClock, FiUser } from 'react-icons/fi';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';

const SingleTicketMicroSite = () => {
     const [data, setData] = useState([])
     const [date, setDate] = useState('')
     const [dateSelected, setDateSelected] = useState(null)
     const [lengthCategory, setLengthCatgory] = useState([])
     const [noOfLines, setNoOfLines] = useState(5);
     const [description, setDescription] = useState(true)
     const value = useContext(GlobalContext);
     const navigate = useNavigate()
     const params = useParams()
     const fontCol = useColorModeValue('blackAlpha.700', 'whiteAlpha.700')
     const monthNames = moment.monthsShort(); // Mendapatkan array nama-nama bulan singkat (3 huruf)
     const today = moment();
     // const nextWeek = today.add(7, 'days');

     // Format tanggal dalam format kalender (YYYY-MM-DD)
     // const formattedNextWeek = nextWeek.format('YYYY-MM-DD')

     // const timeA = moment("01-07-2023", "DD-MM-YYYY").format();
     // const timeB = moment("09-07-2023", "DD-MM-YYYY").format();

     // const dateNow = moment().format();
     // console.log(dateNow)
     // const timeFrom = "09-07-2023";
     // const timeTo = "01-07-2023";
     // const timeArr = moment("09-07-2023", "DD-MM-YYYY").diff(moment("01-07-2023", "DD-MM-YYYY"), 'days');

     // let customTimeArr = []
     // for (let index = 0; index < timeArr + 1; index++) {
     //   const dateToGet = moment(timeTo, "DD-MM-YYYY").add(index, 'day').format();
     //   customTimeArr.push(dateToGet)
     // }

     // console.log(customTimeArr, "customTimeArr")


     const getData = async () => {

          // const dummy = [
          //      {
          //           id: 1,
          //           title: 'Seminar Melipatgandakan Uang ',
          //           speakerImg: 'https://www.parenting.co.id/img/images/LELA28_shutterstock_800x400.jpg',
          //           bannerImg: 'https://down-id.img.susercontent.com/file/cddc4f5f4268196c7db3be3f9e447b9e_tn',
          //           description: 'Pada kelas kali ini kamu akan mendapatkan bekal awal sebelum menyajikan konten-konten yang punya value lebih untuk audiencemu dan sesuai dengan passionmu',
          //           tnc: 'Kids > 6 Tahun, teens & adults, untuk bayi tidak di rekomendasikan karena ruangan memiliki light sensitive.',
          //           dateStart: '2023-08-22',
          //           ticketType: 'offline',
          //           address: 'Entreprenuers.id',
          //           location: 'https://goo.gl/maps/xZn2NLmBTsMS8eVr5',
          //           avail: true,
          //           logoCompany: 'https://deoapp-web.web.app/static/media/deoapp%20colors%20(4).20631dfbbabe92c4cafe.png',
          //           companyName: 'PT Edrus',
          //           speaker: 'Nathan Aldevin',
          //           price: 100000,
          //           category: [{
          //                id: 1,
          //                title: 'Standart',
          //                price: 100000,
          //                detail: 'Seminar dan makan siang',
          //                tickets: [{
          //                     title: 'Copper',
          //                     price: 100000,
          //                     endTicket: '2023-07-24',
          //                     notes: '- PLEASE EXCHANGE YOUR TICKET MIN. 30 MINUTES BEFORE YOUR CHOSEN CATEGORIES'
          //                }]
          //           }]

          //      },
          //      {
          //           id: 2,
          //           title: 'Seminar Online Digital Marketing',
          //           speakerImg: 'https://assets1.cbsnewsstatic.com/hub/i/2018/11/06/0c1af1b8-155a-458e-b105-78f1e7344bf4/2018-11-06t054310z-1334124005-rc1be15a8050-rtrmadp-3-people-sexiest-man.jpg',
          //           bannerImg: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/online-workshop-virtual-meeting-webinar-design-template-e24dc6cd6b2e97c484c868f44a8ebcf1_screen.jpg?ts=1644807838',
          //           description: 'Leave the drabness of the mundane world behind as Ghostival whisks you away to a realm overflowing with creativity and joy. It is a place where problems are overshadowed by boundless exploration and where every color of the rainbow comes to life in a breathtaking display of imagination. In this fantastical world, you’ll encounter a cast of endearing GOWS, brought to life by WD Willys extraordinary imagination. These playful and lovable apparitions shed their spooky reputation and invite you into their vibrant world of fun and joy to take a break from the often dark real world. Experience art like never before in this enchanting world, brought to life by Indonesian illustrator WD Willy and Museum of Toys.',
          //           tnc: 'Tidak boleh membawa makanan & minuman dalam bentuk apapun selama memasuki experience room',
          //           dateStart: '2023-08-01',
          //           dateEnd: '2023-08-02',
          //           ticketType: 'online',
          //           speaker: 'Nathan Aldevin',
          //           time: '07.00 - 22.00 WIB',
          //           address: 'Zoom',
          //           location: 'https://goo.gl/maps/xZn2NLmBTsMS8eVr5',
          //           logoCompany: 'https://deoapp-web.web.app/static/media/deoapp%20colors%20(4).20631dfbbabe92c4cafe.png',
          //           companyName: 'PT Edrus',
          //           avail: false,
          //           price: 100000,
          //           category: [{
          //                id: 1,
          //                title: 'Standart',
          //                price: 100000,
          //                detail: 'Seminar dan makan siang',
          //                tickets: [{
          //                     title: 'Ruby',
          //                     price: 150000,
          //                     endTicket: '2023-07-29',
          //                     notes: '- PLEASE EXCHANGE YOUR TICKET MIN. 30 MINUTES BEFORE YOUR CHOSEN CATEGORIES',
          //                }, {
          //                     title: 'Copper',
          //                     price: 100000,
          //                     endTicket: '2023-07-24',
          //                     notes: '- PLEASE EXCHANGE YOUR TICKET MIN. 30 MINUTES BEFORE YOUR CHOSEN CATEGORIES'
          //                }]
          //           }, {
          //                id: 2,
          //                title: 'VIP',
          //                price: 200000,
          //                detail: 'Seminar, makan siang, dan makan malam',
          //                tickets: [{
          //                     title: "Emerald",
          //                     price: 200000,
          //                     endTicket: '2023-07-29',
          //                     notes: `- PLEASE EXCHANGE YOUR TICKET MIN. 30 MINUTES BEFORE YOUR CHOSEN CATEGORIES - TICKET CAN NOT BE REPLACED`,
          //                     sold: true
          //                },
          //                {
          //                     title: "Diamond",
          //                     price: 250000,
          //                     endTicket: '2023-07-29',
          //                     notes: '- PLEASE EXCHANGE YOUR TICKET MIN. 30 MINUTES BEFORE YOUR CHOSEN CATEGORIES',
          //                },
          //                ]
          //           }]
          //      }
          // ]
          // const filteredData = dummy?.filter(item => item.id == params.id);
          const res = await getSingleDocumentFirebase('tickets', params?.id)
          setData(res)
          console.log("res", res);
     }

     useEffect(() => {
          getData()

          return () => {
               setData()
          }
     }, [])


     const getDate = async () => {
          const timeArr = moment(data?.dateEnd, "YYYY-MM-DD").diff(moment(data?.dateStart, "YYYY-MM-DD"), 'days');
          const startDate = moment(data?.dateStart, 'YYYY-MM-DD').format('YYYY-MM-DD');

          const dates = [];

          for (let index = 0; index < timeArr + 1; index++) {
               const dateToGet = moment(startDate, "YYYY-MM-DD").add(index, 'day');
               const formattedDate = `${dateToGet.format("DD")} ${monthNames[dateToGet.month()]} ${dateToGet.format("YYYY")}`;
               dates.push(formattedDate);
          }

          setDate(dates);
          const allPanelIndices = [...Array(data?.category?.length).keys()];
          setLengthCatgory(allPanelIndices)
     }


     const DescriptionTabCmponent = ({ item, index }) => {
          return (
               <Box key={index} >
                    <Text align='justify'>
                         {item?.description}
                    </Text>
                    <Box
                         borderLeftWidth={3}
                         borderColor={value?.webConfig?.brand[0]}
                         p={2}
                         my='5'
                         borderRadius="md"
                    >
                         <Heading size={'sm'}>Terms & Conditions</Heading>
                    </Box>
                    <Text>
                         {item?.tnc}
                    </Text>
               </Box>
          )
     }

     const TicketTabComponent = ({ item, index }) => {
          return (
               <Box key={index}>
                    <Accordion allowMultiple defaultIndex={lengthCategory}>
                         {item?.category?.map((tics, id) => {

                              return (
                                   <AccordionItem key={id} >
                                        <h2>
                                             <AccordionButton>
                                                  <Box as="span" flex='1' textAlign='left'>
                                                       <Heading size={'sm'}>{tics?.title}</Heading>
                                                       <HStack>

                                                            <Text color={fontCol}>start from</Text>
                                                            <PriceTag fontSize={'sm'} isOnSale={true} price={item?.price} />
                                                       </HStack>
                                                  </Box>
                                                  <AccordionIcon />
                                             </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                             {tics?.tickets?.map((x, i) => (

                                                  <Box pos={'relative'} key={i}>

                                                       <VStack key={i} border={'1px solid black'} rounded={8} bgColor={'white'} h={'auto'} p={5} my='2' justify={'left'} align={'left'} >
                                                            <Heading size={'sm'}>{item?.title}</Heading>
                                                            <Text color={fontCol} fontSize={'sm'}>{x?.notes}</Text>
                                                            <HStack>
                                                                 <FiClock />
                                                                 {x?.endTicket &&
                                                                      <Text size={'sm'}>Ended at {moment(x?.endTicket).format("DD")} {monthNames[moment(x?.endTicket).month()]} {moment(x?.endTicket).format("YYYY")}</Text>
                                                                 }

                                                                 {data?.dateEnd ? <Text color={fontCol} fontSize={'md'}>{moment(data?.dateStart).format("DD")} {monthNames[moment(data?.dateStart).month()]} - {moment(data?.dateEnd).format("DD")} {monthNames[moment(data?.dateEnd).month()]} {moment(data?.dateEnd).format("YYYY")}</Text>
                                                                      :
                                                                      <Text color={fontCol} fontSize={'md'}>
                                                                           {moment(data?.dateStart).format("DD")} {monthNames[moment(data?.dateStart).month()]} {moment(data?.dateStart).format("YYYY")}
                                                                      </Text>
                                                                 }
                                                            </HStack>
                                                            <Divider variant={'dashed'} mb={2} />
                                                            <Flex justify={'space-between'} align={'center'}>
                                                                 <PriceTag price={item?.price} />
                                                                 {x.sold === true ? <Text textTransform={'uppercase'} fontWeight={'medium'}>fully booked</Text> :
                                                                      today.isAfter(x?.endTicket) ?
                                                                           <Text color={'red'} textTransform={'uppercase'} fontWeight={'medium'}>sale ended</Text>

                                                                           :
                                                                           <Button
                                                                                size={'sm'}
                                                                                colorScheme={value?.webConfig?.colorScheme}
                                                                                onClick={() => navigate(`/ticket/${params?.id}/${x?.title}/form`)}
                                                                           >
                                                                                {params.id === "FHFdfaoIpGKOYaOSnBwz" ? "Buy Book" : "Buy Ticket"}
                                                                           </Button>
                                                                 }
                                                            </Flex>
                                                       </VStack>
                                                       <Box position={'absolute'} w='20px' h='32px' borderLeftRadius={'75px'} border={'1px solid black'} bottom={'21%'} right={0} bgColor={'white'} borderRight={0} zIndex={2}></Box>
                                                       <Box position={'absolute'} w='20px' h='32px' borderRightRadius={'75px'} border={'1px solid black'} bottom={'21%'} left={0} bgColor={'white'} borderLeft={0} zIndex={2}></Box>
                                                  </Box>

                                             ))}
                                        </AccordionPanel>
                                   </AccordionItem>
                              )
                         })}
                    </Accordion>
               </Box>
          )
     }

     useEffect(() => {
          getDate()
     }, [data?.length !== 0])

     return (
          <>

               <Box p='2' minH="90vh" pos='relative'>
                    <Box >
                         <VStack justify={'left'} align={'left'}>

                              <HStack w='100%' align={'center'} justify={'center'}>
                                   <Image src={data?.thumbnail ? data.thumbnail : data?.thumbnaill} alt={`${data?.title}-banner`} aspectRatio={16 / 9} objectFit={'cover'} rounded={5} />
                              </HStack>
                              <Heading size='sm' fontSize={'lg'}>{data?.title}</Heading>
                              <HStack>
                                   <FiCalendar />
                                   {/* <Text></Text> */}

                                   {data?.dateEnd ? <Text color={fontCol} fontSize={'md'}>{moment(data?.dateStart).format("DD")} {monthNames[moment(data?.dateStart).month()]} - {moment(data?.dateEnd).format("DD")} {monthNames[moment(data?.dateEnd).month()]} {moment(data?.dateEnd).format("YYYY")}</Text>
                                        :
                                        <Text color={fontCol} fontSize={'md'}>
                                             {moment(data?.dateStart).format("DD")} {monthNames[moment(data?.dateStart).month()]} {moment(data?.dateStart).format("YYYY")}
                                        </Text>
                                   }
                              </HStack>
                              {params.id !== "FHFdfaoIpGKOYaOSnBwz" &&
                                   <HStack>
                                        <FiClock />
                                        <Text color={fontCol}>
                                             {data?.time}
                                        </Text>
                                   </HStack>
                              }

                              {
                                   data?.eventType?.map((item, index) => {
                                        if (params.id !== "FHFdfaoIpGKOYaOSnBwz") return (
                                             <Link to={item === 'offline' && data?.location} target={item === 'offline' && '_blank'} rel='noopener norefferer'>
                                                  <HStack key={index}>
                                                       <Icon as={MdLocationOn} boxSize={5} color={'black'} />
                                                       <Text
                                                            color={fontCol}
                                                            cursor={item === 'offline' ? 'pointer' : 'default'}
                                                       >
                                                            {item === 'offline' ? data?.address : 'Zoom'} ({item})
                                                       </Text>
                                                  </HStack>
                                             </Link>
                                        )
                                   })
                              }
                              <HStack align={'center'} gap={2}>
                                   <Image aspectRatio={16 / 9} objectFit={'contain'} w='100px' src={data?.logo} />
                                   <VStack align={'left'}>
                                        <Text color={fontCol}>Diselenggarakan oleh</Text>
                                        <Heading size={'sm'}>{data?.projectName}</Heading>
                                   </VStack>
                              </HStack>


                         </VStack >
                         <SimpleGrid columns={2} my='5' w='full'>
                              <Box onClick={() => setDescription(true)}
                                   borderBottomWidth={3}
                                   borderColor={description ? value?.webConfig?.brand[0] : 'transparent'}
                                   p={2}
                                   borderRadius="md"
                                   cursor={'pointer'}
                              >
                                   <Heading textTransform={'uppercase'} size={'sm'} textAlign={'center'}

                                   >description</Heading>
                              </Box>
                              <Box onClick={() => setDescription(false)}
                                   borderBottomWidth={3}
                                   cursor={'pointer'}
                                   borderColor={!description ? value?.webConfig?.brand[0] : 'transparent'}
                                   p={2}
                                   borderRadius="md">
                                   <Heading textTransform={'uppercase'} size={'sm'} textAlign={'center'}>{params?.id === "FHFdfaoIpGKOYaOSnBwz" ? "Buy" : "Tiket"}</Heading>
                              </Box>
                         </SimpleGrid>
                         {description ? <DescriptionTabCmponent item={data} /> : <TicketTabComponent item={data} />}

                    </Box>


               </Box >
               {description === true &&

                    <Box bgColor={'white'} p='2' pos={'sticky'} bottom={0}>
                         <Button w='full' colorScheme={value?.webConfig?.colorScheme} onClick={() => setDescription(false)}>{params?.id === "FHFdfaoIpGKOYaOSnBwz" ? "Beli Buku" : "Buy Ticket"}</Button>
                    </Box>
               }

          </>

     )
}
export default SingleTicketMicroSite