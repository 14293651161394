import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Button, Center, Flex, Grid, HStack, Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Stack, Tag, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { getCollectionFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import { auth } from '../../Config/firebase';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';
import moment from 'moment';
import { PriceTag } from '../../Components/Carts/CartDrawer/PriceTag';
import { Link, useNavigate } from 'react-router-dom';


const OrderHistory = () => {
    const value = useContext(GlobalContext);
    const [orders, setOrders] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedData, setSelectedData] = useState([]);
    const [orderNames, setOrderNames] = useState("");
    const [amount, setAmount] = useState(0);
    const [modalData, setModalData] = useState({});
    const fontCol = useColorModeValue('blackAlpha.700', 'whiteAlpha.700');
    const navigate = useNavigate();
    const [filter, setFilter] = useState("")


    const getOrderHistory = async () => {
        const orderConditions = [
            { field: 'userId', operator: '==', value: auth?.currentUser?.uid },
            { field: 'projectId', operator: '==', value: value?.webConfig?.projectsId },
            { field: 'is_production', operator: '==', value: true }
        ];
        const sortBy = { field: "createdAt", direction: "desc" };


        try {
            const ordersList = await getCollectionFirebase('orders',
                { conditions: orderConditions },
                { sortBy },
                { limitValue: null },
                { startAfterData: null }
                );

            if (ordersList && ordersList.length > 0) {
                setOrders(ordersList?.sort((a, b) => b?.createdAt?.seconds - a?.createdAt?.seconds));
                // console.log({ ordersList })
            }
        } catch (error) {
            console.log(error.message);
        }
    };


    const fetchSinglePaymentDoc = async (args) => {
        const conditions = [{
            field: "external_id", //referenceId inside payment collection is actually docId of orders collection
            operator: "==",
            value: args?.id
        }];

        const sortBy = null;
        const limitValue = null;
        try {
            const result = await getCollectionFirebase('payments', { conditions }, { sortBy }, { limitValue }, { startAfterData: null });
            console.log("result", result[0]);
            setSelectedData(result[0])
            let amountSum = 0;
            // console.log(result[0]?.map(x => parseInt(x.price)).reduce((accumulator, currentValue) => accumulator + currentValue, 0), "anjay");

            //if the status in payments document is "PAID", manually update status in order document to "PAID" too. 
            //This is for making consistent between payment and order statuses.
            if (result && result[0]?.status === "PAID") {
                try {
                    await setDocumentFirebase('orders', args?.id, { paymentStatus: "PAID" }, value?.webConfig?.projectsId);
                    getOrderHistory();
                } catch (error) {
                    console.log(error.message, "---error updating order status")
                }
            }



        } catch (error) {
            console.log(error.message, "---error fetching single payment doc")
        }


    }


    const handleOpenModal = (data) => {
        onOpen();
        setModalData(data);
        fetchSinglePaymentDoc(data); //data === order, typeof data === 'object'
    };

    const handleCloseModal = () => {
        onClose();
        setSelectedData([]);
    };

    useEffect(() => {
        getOrderHistory();
    }, []);

    const List = (props) => {
        const { data, handleOpenModal } = props;
        return (
            <Stack my={2} p={2} borderBottomColor='blackAlpha.400' borderBottomWidth={2}>
                <Flex gap={2} flexDir='row' justifyContent='space-between' onClick={() => console.log(data?.orders)}>
                    <Image onClick={() => navigate(`/course/${data?.orders[0]?.id}`)} src={data?.orders[0]?.image} maxW={20} objectFit="contain" />
                    <Stack>
                        <Text fontSize={14} fontWeight='bold'>{data?.orders?.map(item => { return item.name })?.join(", ")}</Text>
                        {data?.orders?.length > 1 ?
                            <Text fontSize={10} color='gray' fontWeight='bold'>{data?.orders?.length + "produk lainnya"}</Text> : null}

                        <Text fontSize={10} color='gray' fontWeight='bold'>{data?.id}</Text>
                        <Text fontSize={12} fontWeight={500} color="gray.500">{moment.unix(data?.createdAt?.seconds)?.format('dddd MMMM Do YYYY, h:mm:ss a')}</Text>
                    </Stack>
                    <Badge borderRadius={5} height="fit-content" colorScheme={data?.paymentStatus === "PENDING" ? "red" : data?.paymentStatus === "PAID" ? "green" : ""}>
                        {data?.paymentStatus}
                    </Badge>
                </Flex>

                <Flex flexDir="row" justifyContent="flex-end">

                    <Flex gap={1}>
                        <Button
                            size='xs'
                            variant='outline'
                            colorScheme='green'
                            onClick={() => {
                                handleOpenModal(data)
                                let str = ''
                                if (data?.orders?.length >1) {
                                    data?.orders?.forEach((x) => {
                                        str += (x?.name + ', ')
                                    })
                                } else {
                                    data?.orders?.forEach((x) => {
                                        str += x?.name
                                    })
                                }
                               
                                setOrderNames(str)
                            }}
                        >
                            {data?.paymentStatus === "PENDING" ? "Check Payment Status" : data?.paymentStatus === "PAID" ? "Review" : ""}
                        </Button>
                        {data?.paymentStatus === "PAID" &&
                            <Button
                                size='xs'
                                variant='solid'
                                colorScheme='blue'
                                onClick={() => navigate(`/course/${data?.orders[0]?.id}`)}
                            >Go to Course</Button>}
                    </Flex>
                </Flex>
                {data?.orders?.length > 1 &&
                    <Accordion allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box flex='1' textAlign='left' fontSize={12} color='gray' fontWeight='bold'>
                                        Courses
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            {data?.orders?.length > 1 && data?.orders?.map((x, i) => (
                                <AccordionPanel pb={4} key={i}>
                                    <Flex justifyContent='space-between'>
                                        <Text fontSize={13} fontWeight={500} color={fontCol}>{x?.name}</Text>
                                        <Button
                                            size='sm'
                                            w={20}
                                            fontSize={10}
                                            variant='outline'
                                            colorScheme={value?.webConfig?.colorScheme}
                                            onClick={() => navigate(`/course/${x.id}`)}
                                        >
                                            {data?.paymentStatus === "PAID" ? "Watch Video" : "Go to video"}
                                        </Button>
                                    </Flex>
                                </AccordionPanel>
                            ))}
                        </AccordionItem>
                    </Accordion>}

            </Stack>
        )
    };

    return (
        <>
            <Flex w={'full'} columns='2' bgColor='brand.1' p='2' justifyContent={'space-between'}>
                <Heading size="md" color={'black'}>Order History</Heading>
                <Select w={'25%'} onChange={(e) => setFilter(e.target.value)}>
                    <option value={'all'}>ALL</option>
                    <option value={'paid'}>PAID</option>
                    <option value={'pending'}>PENDING</option>
                </Select>
            </Flex>

            {filter === '' || filter === 'all' ?
                <>
                    {orders && orders?.length > 0 ? orders?.map((order, i) => (
                        <>
                            <List data={order} key={i} handleOpenModal={handleOpenModal} />
                        </>

                    ))
                        : <Text>You have no orders</Text>}

                </> :
                filter === 'paid' ?
                    <>
                        {orders && orders?.filter(x => x?.paymentStatus === 'PAID')?.length > 0 ? orders?.filter(x => x?.paymentStatus === 'PAID')?.map((order, i) => (
                            <List data={order} key={i} handleOpenModal={handleOpenModal} />
                        ))
                            : <Text>You have no paid orders</Text>}
                    </> :
                    filter === 'pending' ?
                        <>
                            {orders && orders?.filter(x => x?.paymentStatus === 'PENDING')?.length > 0 ? orders?.filter(x => x?.paymentStatus === 'PENDING')?.map((order, i) => (
                                <List data={order} key={i} handleOpenModal={handleOpenModal} />
                            ))
                                : <Text>You have no pending orders</Text>}
                        </> : ''}

            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{selectedData?.id}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack>
                            {/* <HStack>
                                <Heading size={'sm'}>Name:</Heading>
                                <Text>{selectedData?.firstName || selectedData?.buyerName}</Text>
                            </HStack>
                            <HStack>
                                <Heading size={'sm'}>Email:</Heading>
                                <Text>{selectedData?.email || selectedData?.buyerEmail}</Text>
                            </HStack> */}
                            <HStack>
                                <Heading size={'sm'}>Orders:</Heading>
                                <Flex gap={5}>
                                    <Text>{orderNames}</Text>
                                </Flex>

                            </HStack>
                            <HStack>
                                <Heading size={'sm'}>Amount:</Heading>
                                {/* <Text> {JSON.stringify(modalData)}</Text> */}
                                <PriceTag price={modalData?.orders?.map((x) => parseInt(x?.price))?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)} />
                            </HStack>
                            <HStack>
                                <Heading size={'sm'}>Status Payment:</Heading>
                                <Tag colorScheme={modalData?.paymentStatus?.toLowerCase() === 'pending' ? 'red' : modalData?.status?.toLowerCase() ? 'green' : 'green'}>
                                    {modalData?.paymentStatus}
                                </Tag>

                            </HStack>
                            {selectedData?.status !== "PAID" && <Box>
                                <Heading size={'sm'}>Payment URL:</Heading>
                                <Link
                                    to={selectedData?.redirectUrl || selectedData?.Data?.Url}
                                    style={{ color: "blue", textAlign: 'center' }}
                                >
                                    {selectedData?.redirectUrl || selectedData?.Data?.Url || "-"}
                                </Link>
                            </Box>}
                            <HStack>
                                <Heading size={'sm'}>Status Link:</Heading>
                                <Text>{moment(selectedData?.responseParam?.expiryLink).format('LLLL')}</Text>
                            </HStack>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleCloseModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default OrderHistory