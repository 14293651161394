import { AspectRatio, Badge, Box, Center, Container, Flex, Heading, HStack, Spacer, Image, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import HomeCarousel from '../../Components/Carousel/HomeCarousel'
import { CarouselComponent } from '../../Components/Galleries/Carousel/CarouselComponent'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'
import MicroFullLayout from '../../Layouts/MicroFullLayout'
import ReactPlayer from 'react-player'
import { useState } from 'react'
import { getCollectionFirebase } from '../../Api/firebaseApi'
import { useEffect } from 'react'
import { IoRestaurant, IoSchool } from 'react-icons/io5'
import { FcFolder, FcTodoList } from 'react-icons/fc'
import { HiTicket } from "react-icons/hi";



function HomeMicroSite() {
     const [data, setData] = useState(null);
     const navigate = useNavigate()

     const value = useContext(GlobalContext);
     const bgCol = useColorModeValue('white', 'gray.800')
     const fontCol = useColorModeValue('blackAlpha.700', 'whiteAlpha.700')
     const fontColBanner = useColorModeValue('blackAlpha.500', 'whiteAlpha.500')
     const bgBanner = useColorModeValue('orange.50', 'blackAlpha.600')


     const BuiltWithComponent = (props) => (
          <Flex w='full' bg={bgBanner} flexDir='row' justifyContent='center' {...props}>
               <Text textAlign='center' color={fontColBanner} fontSize={12}>This page is built by Deoapp.com</Text>
          </Flex>
     );

     const getData = async () => {
          const conditions = [
               { field: "projectsId", operator: "==", value: value?.webConfig?.projectsId },
          ];
          const sortBy = { field: "createdAt", direction: "asc" };

          try {
               const res = await getCollectionFirebase(
                    "courses",
                    { conditions },
                    { sortBy },
                    { limitValue: 4},
                    { startAfterData: null }
               );
               setData(res);
          } catch (error) {
               console.log(error, "ini error");
          }
     };

     useEffect(() => {
          getData();

          return () => {
               setData([]);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

     return (
          <>
               <MicroFullLayout>
                    {/* <BuiltWithComponent my={30}/> */}

                    <Box>
                         <CarouselComponent images={value?.webConfig?.banner ? value?.webConfig?.banner : null} aspectRatio={16 / 9} />
                         <Center>
                              <Box boxShadow={'md'} bgColor={bgCol} borderRadius={5} mt={-10} zIndex={2} maxW='90%'>
                                   <HStack p='5' spacing={10}>
                                        {value?.webConfig?.features?.map((item, index) => (
                                             <Flex direction='column' align='center' onClick={() => navigate(`/${item}`)} cursor='pointer' key={index}>
                                                  {item === 'course' ?
                                                       <FcFolder fontSize='25px' />
                                                       : item === 'listing' ? <FcTodoList fontSize='25px' /> : item === 'ticket' ? <HiTicket color='#a2d2ff' fontSize='26px' /> : <></>
                                                  }
                                                  <Text fontSize='sm' fontWeight={'medium'} key={index} textTransform={'capitalize'}>{item === "ticket" ? "event" : item}</Text>
                                             </Flex>
                                        ))}
                                   </HStack>
                              </Box>
                         </Center>
                    </Box>

                    {/* <SocialMedia my={7} /> */}
                    {/* <HeadlineComponent /> */}


                    {/*  FEATURES  */}
                    <Box>
                         {value?.webConfig?.features?.map((x, i) => {
                              return (
                                   <Container bg={bgCol} key={i} mb='3' p='2'>
                                        <HStack mx={2}>
                                             <Heading fontSize='lg' textTransform={'capitalize'}>{x === "ticket" ? "event" : x}</Heading>
                                             <Spacer />
                                             <Link to={`/${x}`}>
                                                  <Text fontSize='xs' as={'i'} color={'blue.500'}>See More...</Text>
                                             </Link>
                                        </HStack>

                                        <Center mt={5}>
                                             <HomeCarousel type={x} fontCol={fontCol} />
                                        </Center>
                                   </Container>
                              )
                         }
                         )}
                    </Box>

                    {/* <StatisticsComponent my={4} /> */}


                    {/* VIDEO THUMBNAIL */}
                    {value?.webConfig?.videoThumbnail ?
                         <Box p='2' my={5}>
                              <AspectRatio ratio={16 / 9} maxWidth='100%'>
                                   <ReactPlayer
                                        width='100%'
                                        controls={true}
                                        height='100%'
                                        url={value?.webConfig?.videoThumbnail}
                                   />
                              </AspectRatio>
                         </Box>
                         : null}

                    {/*  NEWS  */}
                    <Box my={4}>
                         {value?.news ? (
                              <Container bg={bgCol} p='2'>
                                   <HStack mx={2}>
                                        <Heading fontSize='lg'>Latest Articles</Heading>
                                        <Spacer />
                                        <Link to='news'>
                                             <Text fontSize='xs' as={'i'} color={'blue.500'}>See More...</Text>
                                        </Link>
                                   </HStack>
                                   {value?.news?.length > 0 ? value?.news?.map((x, i) =>
                                        <Link to={`/news/${x.id}`} key={i}>
                                             <HStack mt='5' borderRadius='md' overflow='hidden' align='start' key={i} position='relative'>
                                                  <Image zIndex={2} objectFit="cover" w='130px' h='130px' src={x.thumbnail} alt={x?.title} />
                                                  <VStack align='end' h='100%'>
                                                       <VStack align={'start'} p='2'>
                                                            <Heading fontSize='sm' noOfLines={2}>{x.title} </Heading>
                                                            <Text fontSize='sm' noOfLines={2} >{x.description}   </Text>
                                                       </VStack>
                                                       <HStack px='5' position='absolute' bottom='2' overflow="scroll">
                                                            {x.tags && x?.tags?.length > 0 ? x?.tags?.map((tag, i) => (
                                                                 <Badge colorScheme={value.webConfig.colorScheme} key={i} borderRadius={5} fontSize={'x-small'} px='2'>{tag}</Badge>
                                                            )) : null}
                                                       </HStack>
                                                  </VStack>
                                             </HStack>
                                        </Link>
                                   ) : <Text textAlign={'center'} fontSize={'sm'} mt='4' color={'gray.500'}>There is no article</Text>}
                                   {/* <Center mt={5}>
								<HomeCarousel type='news' data={value?.news} />
							</Center> */}
                              </Container>
                         ) : null}
                    </Box>

                    <BuiltWithComponent />
               </MicroFullLayout>
          </>
     )
}

export default HomeMicroSite